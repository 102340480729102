import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

/**
 * Used to control the fullscreen PDF Viewer.
 */
export const usePdfViewerStore = defineStore('pdfViewer', () => {
  // ===========
  // State
  // ===========

  /**
   * Private variable that will control the fullscreen PDF Viewer. It will be shown when the document is available and
   * not undefined. The document will be present as a string url.
   */
  const fullscreenDocument = ref<string | undefined>(undefined)

  const fullscreenDocumentId = ref<string | undefined>(undefined)
  // ===========
  // Computed
  // ===========

  /**
   * Returns a boolean indicating whether the document is available.
   */
  const isFullscreenDocumentAvailable = computed(() => fullscreenDocument.value !== undefined)

  // ===========
  // Actions
  // ===========

  /**
   * Removes the document and hides the fullscreen PDF Viewer.
   */
  function dismiss() {
    fullscreenDocument.value = undefined
  }

  return {
    fullscreenDocument,
    fullscreenDocumentId,
    isFullscreenDocumentAvailable,
    dismiss
  }
})
