import {
  CandidateDocument,
  Education,
  JobApplicationProgress,
  LanguageSkill,
  PipelineStep,
  Project
} from '@/models/Project'
import { defaultPipeline, Pipeline } from '@/models/Pipeline'
import { ProjectDto } from '@/models/ProjectDto'
import { Contact, Contacts } from '@/models/Contact'
import { Candidate, Document, Evaluation, UniversityEducation } from '@/models/Candidate'
import { Step } from '@/models/Step'
import { fetcher } from '@/http'
import { useConfigurationStore } from '@/pinia/configStore'

export async function mapProjectToDto(project: Project): Promise<ProjectDto> {
  const pipelineSteps = mapProjectToPipelineSteps(project)

  const communityPath = findCurrentPath(pipelineSteps)

  const salary = {
    from: project.salary_from,
    to: project.salary_to
  }

  const contacts = mapProjectContacts(project)

  return {
    id: project.id,
    name: project?.name || '',
    clientName: project?.company?.name,
    createdDate: project.created_at,
    actionRequired: false,
    jobStartDate: project.job_starting_date || undefined,
    pathCommunity: communityPath?.stepName || undefined,
    workplace: project.address?.city,
    //TODO status
    salary: salary,
    departmentProfile: project.free_text?.department_profile || '',
    jobDescription: project.free_text?.job_description || '',
    koQuestions: project.free_text?.knockout_questions || '',
    pipeline: mapStepsToPipeline(pipelineSteps),
    contacts: contacts,
    jobApplications: await mapJobApplications(project, pipelineSteps),
    documents: [],
    color: '#02A79F'
  } as ProjectDto
}

export function mapProjectToPipelineSteps(project: Project): PipelineStep[] {
  const pipelineSteps: PipelineStep[] = []

  if (project.path && project.path.path_step) {
    const jobApplicationIndices: { [jobAppId: string]: number } = {}
    const steps = project.path.path_step.map(pathStep => {
      const filteredProgress = pathStep.step.job_application_progress.filter(
        jap => jap.job_application.job_id === project.id
      )
      return { ...pathStep, step: { ...pathStep.step, job_application_progress: filteredProgress } }
    })
    // Iterate through path steps to find the highest index for each job application
    steps.forEach(pathStep => {
      pathStep.step.job_application_progress.forEach(progress => {
        jobApplicationIndices[progress.job_application_id] = Math.max(
          jobApplicationIndices[progress.job_application_id] || 0,
          pathStep.index
        )
      })
    })

    steps.forEach(pathStep => {
      const step = pathStep.step
      if (step) {
        const stepIndex = pathStep.index

        // Filter job application progress items for the current step with the highest index
        const currentProgressItems = step.job_application_progress.filter(progress => {
          const highestIndex = jobApplicationIndices[progress.job_application_id]
          return highestIndex === stepIndex
        })

        // Count the number of rejected job applications for this step
        const rejectedJobApplications = new Set<string>()
        currentProgressItems.forEach(progress => {
          const jobApplication = project.job_application.find(jobApp => jobApp.id === progress.job_application_id)
          if (jobApplication && jobApplication.rejection && jobApplication.rejection.length > 0) {
            rejectedJobApplications.add(jobApplication.id)
          }
        })

        const applicationIds: string[] = step.job_application_progress.map(jp => jp.job_application_id)

        const pipelineStep: PipelineStep = {
          stepId: step.id,
          stepName: step.name,
          stepIndex: pathStep.index,
          applicationIds: applicationIds,
          total: step.job_application_progress.length,
          current: currentProgressItems.length,
          rejected: rejectedJobApplications.size
        }

        pipelineSteps.push(pipelineStep)
      }
    })
  }

  return pipelineSteps.sort((a, b) => a.stepIndex - b.stepIndex)
}

export function mapStepsToPipeline(steps: PipelineStep[]): Pipeline {
  if (!steps) throw new Error('Missing steps in mapStepsToPipeline')

  const pipeline: Pipeline = { ...defaultPipeline }

  const findStep = (stepName: string) =>
    steps.find((s: PipelineStep) => {
      const ret = s.stepName === stepName
      // console.debug(`projectMapper.mapStepsToPipeline() ret`, ret, s.stepName, stepName)
      return ret
    })

  const activeCommunication = findStep('Active')
  if (activeCommunication) {
    pipeline.activeCommunication = {
      current: activeCommunication.current || 0,
      total: activeCommunication.total || 0,
      rejected: activeCommunication.rejected || 0
    }
  }

  const contacted = findStep('Contact')
  if (contacted) {
    pipeline.contacted = {
      current: contacted.current || 0,
      total: contacted.total || 0,
      rejected: contacted.rejected || 0
    }
  }

  const contractNegotiations = findStep('Contract')
  if (contractNegotiations) {
    pipeline.contractNegotiations = {
      current: contractNegotiations.current || 0,
      total: contractNegotiations.total || 0,
      rejected: contractNegotiations.rejected || 0
    }
  }

  const evaluation = findStep('Evaluation')
  if (evaluation) {
    pipeline.evaluation = {
      current: evaluation.current || 0,
      total: evaluation.total || 0,
      rejected: evaluation.rejected || 0
    }
  }

  const firstInterview = findStep('FirstInterview')
  if (firstInterview) {
    pipeline.firstInterview = {
      current: firstInterview.current || 0,
      total: firstInterview.total || 0,
      rejected: firstInterview.rejected || 0
    }
  }

  const followUpInterview = findStep('FollowupInterviews')
  // console.debug(`projectMapper.mapStepsToPipeline() FollowUpInterviews`, followUpInterview, steps)
  if (followUpInterview) {
    pipeline.followUpInterview = {
      current: followUpInterview.current || 0,
      total: followUpInterview.total || 0,
      rejected: followUpInterview.rejected || 0
    }
  }

  const handover = findStep('Handover')
  if (handover) {
    pipeline.handover = {
      current: handover.current || 0,
      total: handover.total || 0,
      rejected: handover.rejected || 0
    }
  }

  const placement = findStep('Placement')
  if (placement) {
    pipeline.placement = {
      current: placement.current || 0,
      total: placement.total || 0,
      rejected: placement.rejected || 0
    }
  }

  const prequalification = findStep('Prequalification')
  if (prequalification) {
    pipeline.prequalification = {
      current: prequalification.current || 0,
      total: prequalification.total || 0,
      rejected: prequalification.rejected || 0
    }
  }

  const presentation = findStep('Presentation')
  if (presentation) {
    pipeline.presentation = {
      current: presentation.current || 0,
      total: presentation.total || 0,
      rejected: presentation.rejected || 0
    }
  }
  // console.debug(`projectMapper.mapStepsToPipeline() returning pipeline`, pipeline, 'from steps', steps)
  return pipeline
}

export function mapProjectContacts(project: Project): Contacts {
  const projectContacts: Contacts = {
    department: [],
    hiringAdvisor: [],
    hiringManager: [],
    recruiter: []
  }

  if (project.job_person) {
    project.job_person.forEach(jp => {
      const mappedContact: Contact = {
        id: jp.id,
        accountId: jp.person?.references?.emmycore || undefined,
        email: jp.person?.email || undefined,
        name: jp.person?.first_name || jp.person?.last_name ? `${jp.person?.first_name} ${jp.person?.last_name}` : '',
        phone: jp.person?.phone_numbers && jp.person.phone_numbers.length > 0 ? jp.person.phone_numbers[0] : undefined,
        //TODO: photoURL seems to be missing
        photoURL: ''
      }
      if (jp.role === 'HR Advisor' || 'Hiring Advisor') {
        projectContacts.hiringAdvisor.push(mappedContact)
        if (jp.role === 'Department') {
          projectContacts.department.push(mappedContact)
        }
        if (jp.role === 'Hiring Manager') {
          projectContacts.hiringManager.push(mappedContact)
        }
        if (jp.role === 'Recruiter') {
          projectContacts.recruiter.push(mappedContact)
        }
      }
    })
  }
  return projectContacts
}

export async function mapJobApplications(project: Project, pipelineSteps: PipelineStep[]) {
  const jobApplications = project.job_application
  const mappedJobApplications: Candidate[] = []
  for (const jobApp of jobApplications) {
    const evaluation: Evaluation = {
      answerToKOQuestions: jobApp.free_text?.answersToKnockOutQuestions,
      changeMotivation: jobApp.free_text?.motivationToChangeJobs,
      personalityLeadership: jobApp.free_text?.personality,
      workExperience: jobApp.free_text?.workExperience,
      rejectionReason: jobApp.rejection?.length > 0 ? jobApp.rejection[0].rejectionReason?.description : undefined
    }
    const stepActions = getStepActions(jobApp.job_application_progress, pipelineSteps)
    const mappedJobApp: Candidate = {
      id: jobApp.candidate_id,
      active: true,
      birthday: jobApp.candidate?.person?.date_of_birth || undefined,
      candidateName: `${jobApp.candidate?.person?.first_name} ${jobApp.candidate?.person?.last_name}`,
      //TODO: no data for current employer
      currentEmployer: '',
      email: jobApp.candidate?.person?.email,
      projectId: jobApp.job_id,
      languageSkills: getLanguageSkills(jobApp.candidate?.language_skill),
      noticePeriod: jobApp.free_text.noticePeriod,
      phone: jobApp.candidate?.person?.phone_numbers[0],
      residenceCity: jobApp.candidate?.person?.address?.city,
      residencePostalCode: Number(jobApp.candidate?.person?.address?.postal_code),
      //TODO: Add rejection
      status: getLatestStepAction(stepActions)?.name,
      salary: jobApp.contract_salary,
      university: mapEducation(jobApp.candidate?.education),
      //vCard?: string,
      willingnessToMove: jobApp.candidate?.free_text?.relocationWillingness,
      willingnessToTravel: jobApp.candidate?.travel_willingness?.toString(),
      communityEvaluation: evaluation,
      stepActions: stepActions,
      documents: await getDocuments(jobApp.candidate.candidate_document ?? []),
      remoteWork: jobApp.candidate.home_office_days_expected || undefined,
      salaryExpectation: jobApp.candidate.free_text.salaryExpectation
    }
    mappedJobApplications.push(mappedJobApp)
  }
  return mappedJobApplications
}

export function getStepActions(jobApplicationProgress: JobApplicationProgress[], pipelineSteps: PipelineStep[]) {
  return jobApplicationProgress.map(jap => {
    return {
      name: convertStepName(pipelineSteps.find(step => step.stepId === jap.step_id)!.stepName),
      timestamp: jap.timestamp
    }
  })
}

function convertStepName(name: string) {
  if (name === 'FollowupInterviews') {
    return 'followUpInterview'
  }
  if (name === 'Contract') {
    return 'contractNegotiations'
  } else if (name === 'Presentation' || 'FirstInterview' || 'Placement') {
    return name[0].toLowerCase() + name.slice(1)
  }
  return undefined
}

export function getLatestStepAction(steps: Step[]) {
  return steps && steps.length > 0
    ? steps.reduce((a, b) => {
        if (!a.timestamp) {
          return b
        }
        if (!b.timestamp) {
          return a
        }
        return a.timestamp > b.timestamp ? a : b
      })
    : undefined
}

export async function getDocuments(candidateDocuments: CandidateDocument[]): Promise<Document[]> {
  const documents: Document[] = []

  for (const cd of candidateDocuments) {
    const mappedDocument: Document = {
      id: cd.document_id,
      title: cd.document.filename,
      type: cd.document.type,
      timestamp: cd.document.created_at,
      mimeType: cd.document.mime_type,
      byteSize: cd.document.size_bytes,
      data: await getBase64FromDownloadId(cd.document_id)
    }

    documents.push(mappedDocument)
  }

  return documents
}

export function getLanguageSkills(languageSkills: LanguageSkill[]) {
  return languageSkills.length > 0 ? languageSkills.map(l => l.language_code) : []
}

const getBase64FromDownloadId = async (id: string): Promise<string> => {
  const configStore = useConfigurationStore()

  const env = configStore.getEnv()

  // Can force unwrap because the configuration store should already be initialized at this point
  // if not it will be good that an error is thrown here
  const baseUrl = env.proxy!.general.apiBaseUrl

  const data = await fetcher(`${baseUrl}/raw/` + id, {
    requestInit: {
      headers: {
        // Can force unwrap because the configuration store should already be initialized at this point
        // if not it will be good that an error is thrown here
        'x-tenant': env.local.id!
      }
    }
  })

  const blob = await data.blob()

  return new Promise<string>(resolve => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      const base64data = reader.result as string
      const base64String = base64data.split(',')[1]

      resolve(base64String)
    }
  })
}

function findCurrentPath(steps: PipelineStep[]) {
  return (
    steps.reduce((highestWithCandidate: PipelineStep | null, currentStep: PipelineStep) => {
      if (
        currentStep.applicationIds.length > 0 &&
        (!highestWithCandidate || currentStep.stepIndex > highestWithCandidate.stepIndex)
      ) {
        return currentStep
      }

      return highestWithCandidate
    }, null) ?? steps.find(step => step.stepIndex === 0)
  )
}

function mapEducation(education: Education[]) {
  const latestEducation =
    education && education.length > 0
      ? education.reduce((a, b) => {
          if (!a.end_date) {
            return b
          }

          if (!b.end_date) {
            return a
          }

          return a.end_date > b.end_date ? a : b
        })
      : undefined

  return latestEducation
    ? ({ name: latestEducation.institution_name, degree: latestEducation.degree } as UniversityEducation)
    : undefined
}
