import { defineStore } from 'pinia'
import { useLoadingStore } from '@/pinia/loadingStore'
import { Project } from '@/models/Project'
import * as Sentry from '@sentry/vue'
import { mapProjectToDto } from '@/data/projectMapper'
import { Document } from '@/models/Candidate'
import { ProjectDto } from '@/models/ProjectDto'
import { useConfigurationStore } from '@/pinia/configStore'
import { useAuthStore } from './authStore'
import { portalFetcher } from '@/lib/portalFetcher'
import { apiService } from '@/lib/apiService'
import { ProjectSummaryDto } from '@/lib/model/ProjectDto'
interface ProjectState {
  projectsLoaded: boolean
  offset: number
  projects: ProjectDto[]
  scrollProjects: ProjectSummaryDto[]
  selectedProject: ProjectSummaryDto | undefined
  filteredProjects: ProjectDto[]
  projectInterval: NodeJS.Timeout | null | undefined
  isAllLoaded: boolean
}

const projectColor = [
  '#02A79F',
  '#45D35C',
  '#74DED9',
  '#337CB7',
  '#E6A01F',
  '#FCEB46',
  '#02A79F',
  '#45D35C',
  '#74DED9',
  '#337CB7',
  '#E6A01F',
  '#FCEB46',
  '#02A79F'
]
/**
 * DEPRECATED: Use useProjectsStore instead.
 */
export const useProjectStore = defineStore('project', {
  state: (): ProjectState => ({
    projects: [],
    scrollProjects: [],
    filteredProjects: [],
    offset: 0,
    projectInterval: null,
    isAllLoaded: false,
    projectsLoaded: false,
    selectedProject: undefined
  }),

  actions: {
    async fetchMoreProjects(limit: number) {
      if (this.isAllLoaded) return this.scrollProjects

      const loadingStore = useLoadingStore()
      loadingStore.start()

      try {
        const response = await apiService.fetchProjects(this.offset, limit)
        console.log(`fetchMoreProjects(): loaded ${response.data.length} projects`, response.data)
        this.scrollProjects.push(...response.data)
        this.offset += response.data.length
        console.log(`fetchMoreProjects(): new offset ${this.offset}`)
        if (response.pagination.total === this.scrollProjects.length) {
          this.isAllLoaded = true // No more data to load
        }
        return this.scrollProjects
      } catch (error) {
        Sentry.captureException(error)
        throw error
      } finally {
        loadingStore.stop()
      }
    },
    async getProjectByIdAsync(id: string): Promise<ProjectDto | undefined> {
      console.warn(`projectStore.getProjectByIdAsync(${id}) - OLD`)
      if (!this.projectsLoaded) {
        await this.waitForProjectsToLoad()
      }
      const project = this.projects.find(p => p.id === id)
      if (!project) {
        throw new Error(`Project with ID ${id} not found`)
      }
      return project
    },
    async waitForProjectsToLoad() {
      console.debug(`waitForProjectsToLoad()`)
      return new Promise(resolve => {
        const stop = watch(
          () => this.projectsLoaded,
          isLoaded => {
            console.log(`waitForProjectsToLoad(): isLoaded ${isLoaded}`)
            if (isLoaded) {
              console.log(`waitForProjectsToLoad(): All projects loaded`)
              stop() // Stop the watcher once the data is loaded
              resolve(this.projects)
            }
          },
          { immediate: true }
        )
      })
    },
    async getAllProjects() {
      console.warn(`projectStore.getAllProjects() - OLD`)

      const authStore = useAuthStore()
      const loadingStore = useLoadingStore()
      const configStore = useConfigurationStore()
      try {
        const baseUrl = configStore.getEnv().proxy?.general.apiBaseUrl || ''
        loadingStore.start()

        const tenantId = configStore.getOrganizationId()
        const accessToken = authStore.getAccessToken()
        const idToken = authStore.getIdToken()

        if (!tenantId) {
          throw new Error('No tenant id found')
        }

        if (!accessToken || !idToken) {
          throw new Error('No access token or id token found')
        }

        console.log('baseURL', baseUrl)

        const response = await portalFetcher('Jobs', {
          include: '*'
        })

        const projects: Project[] = await response.json()
        console.log('projects', projects)
        const projectPromises = projects.map(async (project, index) => {
          const projectDto = await mapProjectToDto(project)
          projectDto.color = projectColor[index % projectColor.length]
          return projectDto
        })
        this.projects = await Promise.all(projectPromises)
        loadingStore.stop()
        this.projectsLoaded = true
        this.startProjectInterval()
      } catch (error) {
        console.error(error)
        loadingStore.stop()
        // Log the error to Sentry
        Sentry.captureException(error)
      }
    },

    startProjectInterval() {
      if (this.projectInterval) {
        clearInterval(this.projectInterval)
      }

      this.projectInterval = setInterval(async () => {
        // await this.getAllProjects()
      }, 600 * 1000)
    },

    reset() {
      this.projects = []
      if (this.projectInterval) {
        clearInterval(this.projectInterval)
        this.projectInterval = null
      }
    },

    getCompleteCandidate(id: string) {
      console.warn(`projectStore.getCompleteCandidate(${id}) - OLD`)
      const candidateApplications = this.projects.map(project =>
        project.jobApplications.find(jobApplication => jobApplication.id === id)
      )
      return candidateApplications.find(candidate => candidate != undefined)
    },

    getAllDocuments(id: string) {
      console.warn(`projectStore.getAllDocuments(${id}) - OLD`)
      const project = this.projects.find(project => project.id === id)
      const result = project
        ? project.jobApplications.reduce((r, obj) => r.concat(obj.documents), [] as Document[])
        : []
      return result.filter(document => document.type !== 'photo')
    },

    getProjectById(id: string) {
      console.warn(`projectStore.getProjectById(${id}) - OLD`)
      return this.projects.find(project => project.id === id)
    },
    getProjectFromCandidate(route: any) {
      console.warn(`projectStore.getProjectFromCandidate(${route}) - OLD`)
      if (route.name === 'Candidate') {
        const candidates = this.projects.map(project =>
          project.jobApplications.find(jobApplication => jobApplication.id === route.params.id)
        )

        return candidates?.find(candidate => candidate != undefined)?.projectId
      }
      return false
    }
  }
})
