import { JobApplicationDto } from '@/services/api/models/job-application.model'
import { JobDto } from '@/services/api/models/job.model'

import { useAuthStore } from '@/pinia/authStore'
import { useConfigurationStore } from '@/pinia/configStore'

import { DocumentXFDFData } from '@/lib/model/DocumentDto'
import { JobApplicationDocumentDto } from '@/lib/model/JobApplicationDocumentDto'
import { Document } from '@/models/Candidate'

import {
  CandidateAssessment,
  CandidateInterview,
  CandidateSummary,
  CandidateSummaryResponse,
  JobApplicationInformation,
  ProjectCandidateSummaryDto
} from './model/CandidateDto'
import { JobDetails } from './model/JobDetails'

interface FetchCandidatesOptions {
  step?: string
  state?: 'active' | 'inactive'
}

interface FetchOptions {
  method?: string
  body?: any | null
  fakeIdTokenOverwrite?: string
  raw?: boolean
}

async function fetchAPI(endpoint: string, options: FetchOptions = {}) {
  const configStore = useConfigurationStore()
  const authStore = useAuthStore()

  if (!configStore.isConfigured) {
    throw new Error('No tenant configuration found while fetching data from the API')
  }

  if (!authStore.isAuthenticated || !authStore.getIdToken()) {
    throw new Error('No authenticated user found while fetching data from the API')
  }

  console.debug(`apiService.fecthAPI() Using env `, useConfigurationStore().getEnv())
  const baseUrl = useConfigurationStore().getEnv().proxy?.general.apiBaseUrl

  if (!baseUrl) {
    throw new Error('No proxy configured while fetching data from the API')
  }

  console.debug(`apiService.fecthAPI() using baseUrl ${baseUrl}`)

  if (options.fakeIdTokenOverwrite) {
    console.warn('Using fake id token for fetching data from the API')
  }

  const idToken: string = options.fakeIdTokenOverwrite ?? authStore.getIdToken()!

  const tenantId = configStore.getOrganizationId()

  // FIXME: 31.05.2024 The api is able to determine the tenant id from the id token. But as of right now there is no longer a "tenant_id" property inside the id token.
  const headers = new Headers({
    IdToken: idToken,
    ...(tenantId ? { 'x-tenant': tenantId } : {})
  })
  if (!options.raw) {
    headers.append('Content-Type', 'application/json')
  }

  const config = {
    method: options.method || 'GET',
    headers: headers,
    body: options.body && !options.raw ? JSON.stringify(options.body) : null
  }

  const response = await fetch(`${baseUrl}/${endpoint}`, config)
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`)
  }
  return !options.raw ? response.json() : response
}

export const apiService = {
  // PROJECTS
  async fetchProjects(offset: number, limit: number) {
    const url = `api/ui/v5/projects?offset=${offset}&size=${limit}`
    console.debug(`apiService.fetchProjects() ${url}`)
    const response = await fetchAPI(url)
    return response as PaginatedResponse<JobDto>
  },

  async fetchProjectDetails(id: string) {
    const url = `api/ui/v5/projects/${id}`
    console.debug(`apiService.fetchProjectDetails() ${url}`)
    const response = await fetchAPI(url)
    return response as JobDetails
  },

  async fetchProjectDocuments(id: string) {
    const url = `api/ui/v5/projects/${id}/documents`
    console.debug(`apiService.fetchProjectDocuments() ${url}`)
    const response = await fetchAPI(url)
    return response.data as Document[]
  },

  async fetchProjectCandidatesById(id: string, offset: number, limit: number, options: FetchCandidatesOptions = {}) {
    let url = `api/ui/v5/projects/${id}/candidates?offset=${offset}&size=${limit}`

    if (options.step) {
      url += `&step=${options.step}`
    }
    if (options.state) {
      url += `&state=${options.state}`
    }

    console.debug(`apiService.fetchProjectCandidatesById() ${url}`)
    const response = await fetchAPI(url)
    return response as PaginatedResponse<CandidateSummary>
  },

  // CANDIDATES
  async fetchCandidates(offset: number, limit: number) {
    const url = `api/ui/v5/candidates?offset=${offset}&size=${limit}`
    console.debug(`apiService.fetchCandidates() ${url}`)
    const response = await fetchAPI(url)
    return response as PaginatedResponse<ProjectCandidateSummaryDto>
  },

  async fetchCandidate(id: string) {
    const url = `api/ui/v5/candidates/${id}`
    const response = await fetchAPI(url)
    return response as JobApplicationDto
  },

  async fetchCandidateDocumentsByApplicationId(id: string) {
    const url = `api/ui/v5/candidates/${id}/documents`
    console.debug(`apiService.fetchCandidateDocumentsByApplicationId() ${url}`)
    const response = await fetchAPI(url)
    return response as JobApplicationDocumentDto[]
  },

  async fetchCandidateSummaryByApplicationId(id: string) {
    const url = `api/ui/v5/candidates/${id}/summary`
    console.debug(`apiService.fetchCandidateDocumentsByApplicationId() ${url}`)
    const response = await fetchAPI(url)
    return response as CandidateSummaryResponse
  },

  async fetchCandidateInformationByApplicationId(id: string) {
    const url = `api/ui/v5/candidates/${id}/information`
    console.debug(`apiService.fetchCandidateDocumentsByApplicationId() ${url}`)
    const response = await fetchAPI(url)
    return response as JobApplicationInformation
  },

  async fetchCandidateAssessmentByApplicationId(id: string) {
    const url = `api/ui/v5/candidates/${id}/assessment`
    console.debug(`apiService.fetchCandidateDocumentsByApplicationId() ${url}`)
    const response = await fetchAPI(url)
    return response as CandidateAssessment
  },

  async fetchLatestCandidates(numberOfCandidates?: number) {
    const url = `api/ui/v5/home/candidates${numberOfCandidates ? `?numberOfCandidates=${numberOfCandidates}` : ''}`
    const response = await fetchAPI(url)
    return response as CandidateSummary[]
  },

  async fetchLatestCandidateInterviews(numberOfCandidates?: number) {
    const url = `api/ui/v5/home/interviews${numberOfCandidates ? `?numberOfCandidates=${numberOfCandidates}` : ''}`
    const response = await fetchAPI(url)
    return response as CandidateInterview[]
  },

  async fetchDocumentBlobUrl(id?: string) {
    const url = `raw/${id}`
    console.debug(`apiService.fetchRawDocumentById() ${url}`)
    const response = await fetchAPI(url, { raw: true })
    const blob = await response.blob()
    return URL.createObjectURL(blob)
  },

  async addXfdfData(documentId: string, xfdfData: string): Promise<string | undefined> {
    const url = `api/ui/v5/document/annotation/${documentId}`
    await fetchAPI(url, { method: 'POST', body: { xfdfData: xfdfData } })
    return url
  },

  async getXfdfData(documentId?: string): Promise<DocumentXFDFData | undefined> {
    if (documentId) {
      const url = `api/ui/v5/document/annotation/${documentId}`
      const response = await fetchAPI(url)
      return response as DocumentXFDFData
    } else {
      console.error('apiService.getXfdfData() -> Not all parameters are set!')
      return undefined
    }
  },

  async fetchTicket() {
    const url = `api/ui/v5/tableau/ticket`
    console.debug(`apiService.fetchTicket() ${url}`)
    return await fetchAPI(url)
  }
}
