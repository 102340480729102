import { Auth, Hub } from 'aws-amplify'
import { ref } from 'vue'
import { defineStore } from 'pinia'
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'
import * as Sentry from '@sentry/vue'

import router from '../router'
import store from '../store'

import { AuthStateChangeData } from '../models/AuthStateChangeData'
import { AuthState } from '../models/AuthState'
import { useFirebaseStore } from './firebaseStore'
import { parsePermissions } from '../lib/parsePermissions'
import { Permission } from '@/models/Permission'
import { useProjectStore } from '@/pinia/projectStore'
import api from '@/config/api'
import { useDataStore } from './dataStore'
import { useProjectHistory } from '@/composables/useProjectHistory'

/**
 * Determines whether the session should always be refreshed when the user reloads the page. If this is set to `false`,
 * the session will only be refreshed if the access token has expired. This duration of the tokens validity is set in
 * the Cognito User Pool settings.
 */
const alwaysRefreshSession = true

export const useAuthStore = defineStore('auth', () => {
  // ===========
  // Dependencies
  // ===========

  const firebaseStore = useFirebaseStore()
  const projectStore = useProjectStore()
  const dataStore = useDataStore()

  // ===========
  // State
  // ===========

  /**
   * The `CognitoUser` object. When not signed in, this is `null`.
   */
  const user = ref<CognitoUser | null>(null)

  /**
   * The state of the authentication flow. See `AuthState` for possible values.
   */
  const authenticationState = ref<AuthState>('signOut')

  /**
   * A boolean indicating whether the store has been initialised before.
   */
  const isInitialized = ref(false)

  // ===========
  // Initialisation
  // ===========

  /**
   * Initialise the store and checks for an existing user session. This function can be called multiple times.
   */
  async function init() {
    if (isInitialized.value) {
      console.log('🔑 Reinitializing auth store with updated configuration...')
    } else {
      console.log('🔑 Initializing auth store...')
    }

    // On auth state change, update the user state
    Hub.listen('auth', data => handleAuthEvent(data as AuthStateChangeData))

    // When the app is loaded, check if the user is already signed in
    try {
      // await refreshSession()
      const signedInUser = (await Auth.currentAuthenticatedUser()) as CognitoUser

      // Check if access token has expired
      const expirationTimestampInSeconds = signedInUser.getSignInUserSession()?.getAccessToken().getExpiration()
      const nowInSeconds = Date.now() / 1000

      if (!expirationTimestampInSeconds) {
        console.log('🔑 Unable to find expiration timestamp.')
        await signOut()
        isInitialized.value = true
        return
      }

      if (alwaysRefreshSession) {
        // Will always refresh the session and get a new access and id token to ensure that the user has the latest permissions

        try {
          await refreshSession()
        } catch {
          console.log('🔑 Refresh token has expired.')
          await signOut()
          isInitialized.value = true
          return
        }
      } else {
        // Will only refresh the session if the access token has expired this may cause the user to not have the latest permissions

        if (expirationTimestampInSeconds < nowInSeconds) {
          console.log('🔑 Access token has expired.')
          try {
            await refreshSession()
          } catch {
            console.log('🔑 Refresh token has expired.')
            await signOut()
            isInitialized.value = true
            return
          }
        }
      }

      console.log('🔑 Token will expire: ' + new Date(expirationTimestampInSeconds * 1000).toLocaleString())

      user.value = signedInUser
      authenticationState.value = 'signIn'

      // If the user is signed in, we need to initialise the Firebase store
      await firebaseStore.initialize()
    } catch (error) {
      // An error at this point means that the user is not signed in
      user.value = null
      authenticationState.value = 'signOut'
    }

    isInitialized.value = true
    console.log('🔑 Initialized')
  }

  // ===========
  // Getters
  // ===========

  /**
   * Returns a boolean indicating whether the user is signed in.
   */
  const isAuthenticated = computed(() => {
    return user.value !== null
  })

  // ===========
  // Static Getters
  // ===========

  /**
   * Returns the username of the current user. The value can be `undefined` if the user is not signed in.
   */
  const getUsername = () => user.value?.getUsername()

  /**
   * Returns the given name of the current user. The value can be `undefined` if the user is not signed in.
   */
  const getGivenName = () => user.value?.getSignInUserSession()?.getIdToken().payload.given_name as string | undefined

  /**
   * Returns the family name of the current user. The value can be `undefined` if the user is not signed in.
   */
  const getFamilyName = () => user.value?.getSignInUserSession()?.getIdToken().payload.family_name as string | undefined

  /**
   * Returns the full name of the current user. The value can be `undefined` if the user is not signed in.
   */
  const getFullName = () => {
    const _givenName = getGivenName()
    const _familyName = getFamilyName()

    if (_givenName || _familyName) {
      return `${_givenName} ${_familyName}`.trim()
    } else {
      return undefined
    }
  }

  /**
   * Returns the email address of the current user. The value can be `undefined` if the user is not signed in.
   */
  const getEmail = () => user.value?.getSignInUserSession()?.getIdToken().payload.email as string | undefined

  /**
   * The ID token of the current user.
   */
  const getIdToken = () => {
    return user.value?.getSignInUserSession()?.getIdToken().getJwtToken()
  }

  const getTenantId = () => {
    return user.value?.getSignInUserSession()?.getIdToken().payload['tenant_id']
  }

  /**
   * Returns the payload of the ID token of the current user.
   */
  const getIdTokenPayload = () => {
    return user.value?.getSignInUserSession()?.getIdToken().decodePayload()
  }

  /**
   * The access token of the current user.
   */
  const getAccessToken = () => {
    return user.value?.getSignInUserSession()?.getAccessToken().getJwtToken()
  }

  /**
   * Returns the payload of the access token of the current user.
   */
  const getAccessTokenPayload = () => {
    return user.value?.getSignInUserSession()?.getAccessToken().decodePayload()
  }

  const getExpirationTimestamp = () => {
    return user.value?.getSignInUserSession()?.getAccessToken().getExpiration()
  }

  const checkUserHavePermission = (permission: Permission): boolean => {
    const permissionsString = user.value?.getSignInUserSession()?.getIdToken().payload['permissions']

    if (!permissionsString) {
      return false
    }

    // There is no need to parse it to array and then search it within it, we can simply check if we have it in original string
    return permissionsString.indexOf(permission) > 0
  }

  const getPermissions = () => {
    const permissionsString = user.value?.getSignInUserSession()?.getIdToken().payload['permissions']

    if (!permissionsString) {
      return undefined
    }

    try {
      const permissionsArray = JSON.parse(permissionsString) as string[]
      return parsePermissions(permissionsArray)
    } catch (error) {
      console.log('🔑 Permissions: ' + permissionsString)
      console.error('🔑 Unable to parse permissions: ' + error)
      return undefined
    }
  }

  const hasProjectPermissions = () => {
    const permissions = getPermissions()
    return permissions !== undefined && (permissions.portal.projectIds.length > 0 || permissions.portal.wildcard)
  }

  /**
   * Returns the permissions of the current user.
   */
  const hasPermissions = () => {
    const permissions = user.value?.getSignInUserSession()?.getIdToken().payload.permissions as string[] | undefined
    return permissions !== undefined && permissions.length > 0
  }

  // ===========
  // Actions
  // ===========

  /**
   * This will update the AWS state
   */
  const updateState = async function () {
    try {
      user.value = await Auth.currentAuthenticatedUser()
    } catch {
      user.value = null
    }
  }

  /**
   * Sign in to the cognito user pool
   *
   * @param username The username of the user
   * @param password The password of the user
   * @returns The `CognitoUser` object
   * @see https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/#sign-in
   */
  const signIn = async (username: string, password: string): Promise<CognitoUser> => {
    console.log('🔑 Performing sign in for user:', username)

    // Trim inputs to prevent issues with spaces
    // #365 - 2 validation errors detected strip login fields
    const trimmed = {
      username: username.trim(),
      password: password.trim()
    }

    const signedInUser: CognitoUser = await Auth.signIn({
      username: trimmed.username,
      password: trimmed.password
    })

    const expirationTimestampInSeconds = signedInUser.getSignInUserSession()?.getAccessToken().getExpiration()

    if (expirationTimestampInSeconds === undefined) {
      throw new Error('🔑 Failed to retrieve the expiration timestamp of the access token. Invalid token.')
    }

    // console.log('🔑 Signed in user:', signedInUser)
    console.log('🔑 Token will expire: ' + new Date(expirationTimestampInSeconds * 1000).toLocaleString())
    console.log('🔑 Permissions: ', getPermissions())

    // FIXME: Please reenable this check when the permissions are correctly set
    // if (!hasProjectPermissions()) {
    //   // Actual sign out is performed in the 'onSignIn' hook
    //   throw new Error('🔑 User does not have access to any projects.')
    // }

    return signedInUser
  }

  /**
   * If you want to perform a refresh of the session, you can use the `refreshSession` method. Normally the session will
   * be refreshed automatically when calling either `Auth.currentAuthenticatedUser()` or `Auth.currentSession()`.
   */
  const refreshSession = async () => {
    return new Promise(async (resolve, reject) => {
      const signedInUser = await Auth.currentAuthenticatedUser()
      const currentSession = await Auth.currentSession()

      console.log('🔑 Refreshing session for user:', signedInUser.getUsername())

      signedInUser.refreshSession(currentSession.getRefreshToken(), (err: Error, session: CognitoUserSession) => {
        if (err) {
          reject(err)
        } else {
          resolve(session)
        }
      })
    })
  }

  /**
   * Sign out of the cognito user pool
   *
   * @see https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/#sign-out
   */
  const signOut = async () => {
    console.log('🔑 Performing sign out for user:', getUsername())
    return await Auth.signOut()
  }

  /**
   * Change the password of the current user
   *
   * @param oldPassword The current password of the user
   * @param newPassword The new password of the user
   * @see https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#change-password
   */
  const changePassword = async (oldPassword: string, newPassword: string) => {
    return await Auth.changePassword(user.value, oldPassword, newPassword)
  }

  /**
   * Request a password reset for the entered username
   *
   * @param username The username of the user
   * @see https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#reset-password
   */
  const requestPasswordReset = async (username: string) => {
    const data = await Auth.forgotPassword(username)
    console.log('🔑 Password reset request:', data)
  }

  /**
   * Will reset the password of the user with the given username and the code received by email
   *
   * @param username The username of the user
   * @param code The code received by email
   * @param newPassword The new password of the user
   */
  const resetPassword = async (username: string, code: string, newPassword: string) => {
    const data = await Auth.forgotPasswordSubmit(username, code, newPassword)
    console.log('🔑 Password reset:', data)
  }

  /**
   * Returns an object containing the headers to be used in API requests
   */
  const getRequestHeaders = async (apiType?: string) => {
    if (!isAuthenticated.value) throw new Error('🔑 Requesting headers for unauthenticated user')
    const session = await Auth.currentSession()

    if (apiType && apiType === api.apiType.talk) {
      return {
        IdToken: `${session.getIdToken().getJwtToken()}`,
        'tenant-id': `${session.getIdToken().payload['tenant_id']}`
      }
    } else {
      return {
        IdToken: `Bearer ${session.getIdToken().getJwtToken()}`
      }
    }
  }

  const setUserAttribute = async (attribute: string, value: string) => {
    if (!isAuthenticated.value) {
      console.warn('🔑 Attempting to set user attribute for unauthenticated user. Skipping.')
      return
    }

    return await Auth.updateUserAttributes(user.value, {
      [attribute]: value
    })
  }

  // ===========
  // Event Handlers
  // ===========

  /**
   * Handler for auth events triggered by the amplify `Hub.listen` function
   *
   * @param data The data from the auth event
   * @see https://docs.amplify.aws/guides/authentication/listening-for-auth-events/q/platform/js/
   */
  const handleAuthEvent = async (authStateChange: AuthStateChangeData) => {
    if (authStateChange.channel !== 'auth') return

    const event = authStateChange.payload.event as AuthState

    // It can happen that an event is triggered more than once
    if (event === authenticationState.value && event !== 'tokenRefresh') {
      // console.log('🔑 Duplicated auth event (For some reason the auth event gets fired more than once):', event)
      return
    } else {
      console.log('🔑 Auth event:', authStateChange.payload.event)
    }

    // Update the authentication state
    authenticationState.value = event

    let user

    switch (event) {
      case 'signIn':
        // The signIn event is triggered only when the user successfully signs in
        // The `data` property is the `CognitoUser` object and is always present
        user = authStateChange.payload.data!
        await onSignIn(user)
        break
      case 'tokenRefresh':
        onRefreshToken()
        user = await Auth.currentAuthenticatedUser()
        break
      case 'signOut':
        await onSignOut()
        break
      case 'forgotPassword':
        const payload = authStateChange.payload
        console.log(payload)
        break
      case 'signUp':
      case 'signIn_failure':
      case 'configured':
      default:
        break
    }
  }

  /**
   * Sign in handler
   *
   * @param newUser The `CognitoUser` of the signed in user
   */
  const onSignIn = async (newUser: CognitoUser) => {
    user.value = newUser

    // Set Sentry context
    Sentry.setUser({
      username: newUser.getUsername()
    })

    // FIXME: Please reenable this check when the permissions are correctly set
    // When the user has to projects assigned to him return to the login page
    // and logout
    // if (!hasProjectPermissions()) {
    //   console.warn('🔑 User does not have access to any projects. Signing out.')
    //   await signOut()
    //   return
    // }

    // Dispatch application data fetch
    await router.push({ name: 'Home' })

    // Initialize firebase
    firebaseStore.initialize()

    // Initialize data store
    dataStore.initialize()

    // Dispatch application data fetch in old vuex store
    // TODO: Create a new pinia store to handle this
    // await projectStore.getAllProjects()
    // store.dispatch('GET_ALL_PROJECTS')
    //store.dispatch('GET_ALL_CHATS')
    // store.dispatch('GET_ALL_NOTIFICATIONS')
  }

  /**
   * Handler for when the token is refreshed
   */
  const onRefreshToken = async () => {
    const currentUser = await Auth.currentAuthenticatedUser()

    // Set Sentry context
    Sentry.setUser({
      username: currentUser.getUsername()
    })

    user.value = currentUser
    const expirationTimestampInSeconds = (await Auth.currentSession()).getAccessToken().getExpiration()
    console.log('🔑 Token will expire: ' + new Date(expirationTimestampInSeconds * 1000).toLocaleString())
  }

  /**
   * Sign out handler
   */
  const onSignOut = async () => {
    // Set Sentry context
    Sentry.setUser(null)

    // Has to be executed before any data gets cleared as some parts of the app
    // rely on the user state to be present and may break otherwise
    await router.push({ name: 'Login' })

    // Reset user state
    user.value = null

    // Reset firebase state
    await firebaseStore.deinitialize()

    // Reset data store
    await dataStore.deinitialize()

    // Reset sidebar project state
    useProjectHistory().clear()

    // Dispatch function to store and router
    store.dispatch('RESET')
  }

  const getExpirationTime = async (unit: 's' | 'ms') => {
    try {
      const session = await Auth.currentSession()
      return session.getAccessToken().getExpiration() * (unit === 's' ? 1 : 1000)
    } catch {
      return undefined
    }
  }

  /**
   * Returns a boolean indicating whether the user's access token has expired. When not logged in, the token is
   * considered expired.
   */
  const isExpired = async () => {
    const expirationTimeInMs = await getExpirationTime('ms')
    const nowInMs = Date.now()

    if (expirationTimeInMs === undefined) {
      return true
    }

    return expirationTimeInMs < nowInMs
  }

  // ===========
  // Return
  // ===========

  return {
    init,
    user,
    getUsername,
    refreshSession,
    isAuthenticated,
    updateState,
    isInitialized,
    isExpired,
    signIn,
    signOut,
    changePassword,
    getRequestHeaders,
    requestPasswordReset,
    setUserAttribute,
    resetPassword,
    handleAuthEvent,
    getGivenName,
    getFamilyName,
    getFullName,
    getEmail,
    getAccessToken,
    getIdToken,
    getTenantId,
    getAccessTokenPayload,
    getIdTokenPayload,
    getExpirationTimestamp,
    hasProjectPermissions,
    hasPermissions,
    getPermissions,
    checkUserHavePermission
  }
})

export type AuthStore = ReturnType<typeof useAuthStore>
