import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useLoadingStore } from '@/pinia/loadingStore'
import { fetcher } from '@/http'
import apiConfig from '@/config/api'

/**
 * EmmyTalkDto is the interface for the response from /talks
 *
 * @typedef {Object} EmmyTalkDto
 * @property {string} _id - The ID of the talk
 * @property {string} tenantId - The ID of the tenant
 * @property {string} title - The title of the talk
 * @property {Date} createdAt - The date when the talk was created
 * @property {Date} updatedAt - The date when the talk was last updated
 * @property {string} createdByUser - The user who created the talk
 * @property {string} updatedByUser - The user who last updated the talk
 * @property {Object} reference - The reference object of the talk
 * @property {Object} lastMessage - The last message in the talk
 */
export interface EmmyTalkDto {
  _id: string
  tenantId: string
  title: string
  createdAt: Date
  updatedAt: Date
  createdByUser: string
  updatedByUser: string
  reference: {
    talkType: string
    jobIds: string[]
    applicationIds: string[]
  }
  lastMessage: {
    _id: string
    text: string
    createdAt: Date
    updatedAt: Date
    createdByUser: {
      _id: string
      tenantId: string
      username: string
      firstName: string
      lastName: string
      avatarUrl: string
      isOnline: boolean
    }
    views: any[]
  }
}

/**
 * EmmyTalkReference is an interface that represents a reference to an EmmyTalk. It contains various properties that
 * provide information about the talk.
 *
 * TalkId or candidateId/projectId is required. If talkId is provided, it will be used. If not, candidateId or
 * projectId.
 *
 * @typedef {Object} EmmyTalkReference
 * @property {string} talkType - The type of the talk. This is a required property.
 * @property {string} title - The title of the talk. This is a required property.
 * @property {boolean} isOverlay - A boolean indicating whether the talk is an overlay. This is a required property.
 * @property {string} [talkId] - The ID of the talk. This is an optional property.
 * @property {string} [candidateId] - The ID of the candidate associated with the talk. This is an optional property.
 * @property {string} [projectId] - The ID of the project associated with the talk. This is an optional property.
 * @property {string} [referenceColor] - The color of the reference. This is an optional property.
 */
export interface EmmyTalkReference {
  talkType: string
  title: string
  isOverlay: boolean
  talkId?: string
  candidateId?: string
  projectId?: string
  color?: string
}

const compareTalks = (talk1: EmmyTalkReference, talk2: EmmyTalkReference) => {
  const talk1Id = talk1.talkId ?? talk1.candidateId ?? talk1.projectId
  const talk1Type = talk1.talkType

  const talk2Id = talk2.talkId ?? talk2.candidateId ?? talk2.projectId
  const talk2Type = talk2.talkType
  const talk1ApplicationId = talk1.candidateId
  const talk2ApplicationId = talk2.candidateId

  const talk1ProjectId = talk1.projectId
  const talk2ProjectId = talk2.projectId

  return (
    talk1Id === talk2Id &&
    talk1Type === talk2Type &&
    talk1ApplicationId === talk2ApplicationId &&
    talk1ProjectId === talk2ProjectId
  )
}

export const useTalkStore = defineStore('talk', () => {
  // chats loaded from talkApi rest api
  const talks: Ref<EmmyTalkDto[]> = ref([])

  // opened chats in overlay
  const overlayTalks: Ref<EmmyTalkReference[]> = ref([])

  // true if overlay sidebar is open
  const isOverlaySidebarOpen = ref(false)

  // if talk is opened in fullscreen mode
  const isFullScreen = ref(false)

  // if message is opened in fullscreen mode
  const isTalkFullScreen = ref(false)

  const fullscreenWidth = ref(400)

  // current opened message in fullscreen mode
  const currentTalk: Ref<EmmyTalkReference | undefined> = ref(undefined)

  // active segment in talk list
  const activeSegment: Ref<string> = ref('all')

  const showInactive = ref(false)

  function reset() {
    talks.value = []
  }

  async function fetchTalks() {
    const loadingStore = useLoadingStore()
    loadingStore.start()
    try {
      const response = await fetcher(apiConfig.resources.talks, {
        requestInit: {
          method: 'POST',
          body: JSON.stringify({
            applicationIds: [],
            jobIds: [],
            talkType: ['standard']
          })
        },
        apiType: 'talk',
        baseUrlOverwrite: ''
      })
      talks.value = await response.json()
      console.debug('talkStore.fetchChats', talks.value)
    } catch (err) {
      console.error('talkStore.fetchChats', err)
    } finally {
      loadingStore.stop()
    }
  }

  function openTalk(talk: EmmyTalkReference) {
    console.debug('talkStore.openTalk', talk)
    currentTalk.value = talk
  }

  function openOverlayTalk(talk: EmmyTalkReference) {
    if (
      !overlayTalks.value.find(t => {
        return compareTalks(t, talk)
      })
    ) {
      console.debug('talkStore.openOverlayTalk - new overlay talk', talk)
      overlayTalks.value = [...overlayTalks.value, talk]
      isOverlaySidebarOpen.value = true
    }
  }

  function closeOverlayTalk(talk: EmmyTalkReference) {
    overlayTalks.value.splice(
      overlayTalks.value.findIndex(t => {
        return compareTalks(t, talk)
      }),
      1
    )
  }

  function closeAllOverlayTalks() {
    overlayTalks.value = []
  }

  function openOverlaySidebar() {
    isOverlaySidebarOpen.value = true
  }

  function closeOverlaySidebar() {
    isOverlaySidebarOpen.value = false
  }

  function toggleOverlaySidebar() {
    isOverlaySidebarOpen.value = !isOverlaySidebarOpen.value
  }

  function toggleFullScreen() {
    isFullScreen.value = !isFullScreen.value
  }

  function toggleTalkFullScreen(width: number) {
    isTalkFullScreen.value = !isTalkFullScreen.value
    fullscreenWidth.value = isTalkFullScreen.value ? width : 400
  }

  function changeActiveSegment(segment: string) {
    activeSegment.value = segment
  }

  return {
    talks,
    overlayTalks,
    currentTalk,
    isOverlaySidebarOpen,
    isFullScreen,
    isTalkFullScreen,
    fullscreenWidth,
    activeSegment,
    showInactive,
    fetchTalks,
    reset,
    openTalk,
    openOverlayTalk,
    closeOverlayTalk,
    closeAllOverlayTalks,
    openOverlaySidebar,
    toggleOverlaySidebar,
    closeOverlaySidebar,
    toggleFullScreen,
    toggleTalkFullScreen,
    changeActiveSegment
  }
})
