/**
 * A localized string is an object that contains a 2-letter locale code as key and a string as value. The locale code
 * represents the language in an ISO 639-1 format.
 *
 * The `autoLocalizations` property is an array of locale codes that are automatically generated by the system. This can
 * be used inside the application to determine what was automatically generated and what was manually set.
 */
export type LocalizedString = { autoLocalizations?: string[] } & { [locale: string]: string }

/**
 * Localizes the `LocalizedString` object based on the provided locale and fallback locale.
 *
 * Fallback Strategy:
 *
 * 1. The user's locale
 * 2. The fallback locale
 * 3. The 'xx' locale (used as a placeholder for unknown languages)
 * 4. A placeholder `<Missing Label>` if no localization was found
 */
export function localizeValue(
  value: string | LocalizedString,
  fallbackValue?: string
): { value: string; localizationKey?: string; autoGenerated: boolean } | undefined {
  const { locale, fallbackLocale } = useI18n()

  if (typeof value === 'string') {
    return {
      value,
      localizationKey: undefined,
      autoGenerated: false
    }
  } else if (typeof value === 'object') {
    const l = locale.value as string
    const f = fallbackLocale.value as string

    const localizationKey: string = value[l] ? l : value[f] ? f : value['xx'] ? 'xx' : ''

    const localizedValue = value[localizationKey] ?? fallbackValue ?? '<Missing Label>'

    return {
      value: localizedValue,
      localizationKey,
      autoGenerated: value.autoLocalizations?.includes(localizationKey) ?? false
    }
  }

  return undefined
}
