<template>
  <IonApp :class="{ [theme]: true }">
    <!-- Message Box -->
    <MessageCenter id="message-center" v-model="messages" />

    <ProgressBar :is-loading="isLoading" />
    <IonSplitPane content-id="main-content" when="(min-width: 1920px)">
      <PDFViewer
        v-if="isFullscreenDocumentAvailable"
        style="width: 100%; height: 100%; z-index: 2000; max-height: none"
        :document="fullscreenDocument"
        :document-id="fullscreenDocumentId"
        is-full-screen
      />
      <Sidebar v-if="isAuthenticated" />
      <EmmytalkOverlay />
      <ion-router-outlet id="main-content" :animated="false"> </ion-router-outlet>
    </IonSplitPane>
  </IonApp>
</template>

<script>
import { IonApp, IonRouterOutlet, IonSplitPane } from '@ionic/vue'
import { defineComponent } from 'vue'
import { storeToRefs } from 'pinia'

import Sidebar from '@/components/layout/Sidebar.vue'
import ProgressBar from './components/ui-elements/ProgressBar.vue'

import EmmytalkOverlay from './views/Emmy/EmmytalkOverlay.vue'
import PDFViewer from './views/Candidate/components/PDFViewer.vue'

import { useAuthStore } from '@/pinia/authStore'
import { useConfigurationStore } from '@/pinia/configStore'
import { usePdfViewerStore } from '@/pinia/pdfViewerStore'
import { useLoadingStore } from '@/pinia/loadingStore'
import { useMessageStore } from '@/pinia/messageStore'
import { useDataStore } from '@/pinia/dataStore'

import MessageCenter from '@/components/MessageCenter.vue'
import { useProjectStore } from '@/pinia/projectStore'
import { useTalkStore } from '@/pinia/talkStore'

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    IonSplitPane,
    Sidebar,
    EmmytalkOverlay,
    PDFViewer,
    ProgressBar,
    MessageCenter
  },
  setup() {
    const authStore = useAuthStore()
    const configStore = useConfigurationStore()
    const pdfViewerStore = usePdfViewerStore()
    const loadingStore = useLoadingStore()
    const messageStore = useMessageStore()
    const projectStore = useProjectStore()
    const dataStore = useDataStore()
    const talkStore = useTalkStore()

    const { isAuthenticated } = storeToRefs(authStore)
    const { env } = storeToRefs(configStore)
    const { fullscreenDocument, fullscreenDocumentId, isFullscreenDocumentAvailable } = storeToRefs(pdfViewerStore)
    const { isLoading } = storeToRefs(loadingStore)
    const { messages } = storeToRefs(messageStore)

    return {
      isAuthenticated,
      env,
      isLoading,
      configStore,
      isFullscreenDocumentAvailable,
      fullscreenDocument,
      fullscreenDocumentId,
      authStore,
      messages,
      projectStore,
      dataStore,
      talkStore
    }
  },
  data() {
    return {
      platform: 'web'
    }
  },
  computed: {
    theme: function () {
      return this.env.proxy?.general?.theme ?? 'default'
    }
  },
  mounted() {
    // On mount the app checks if there is a lang= query parameter and sets the language accordingly
    // If not the language is set to the browser language
    const queryParam = new URLSearchParams(window.location.search)
    const langQueryParam = queryParam.get('lang')

    const browserLanguage = navigator.language.split('-')[0]

    const language = langQueryParam ?? browserLanguage

    if (browserLanguage.length == 2) {
      this.$i18n.locale = language
      this.configStore.setLocale(language)
    }
  },
  created() {
    if (useRoute().name !== 'Login') {
      this.dataStore.initialize()
      this.talkStore.fetchTalks()
    }
  }
})
</script>

<style scoped lang="scss">
html {
  overflow: auto;
  padding: 0;
  margin: 0;
}

body {
  position: relative;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

#app,
ion-app {
  width: 100%;
  height: 100%;
}

#message-center {
  position: fixed;
  bottom: 24px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  z-index: 1;
}

ion-split-pane {
  --side-max-width: 15%;
  --border: none;
}

ion-router-outlet:deep .ion-page {
  background: var(--background);
  background-size: cover;

  ion-content {
    --background: none;
    --offset-top: 0px !important;
  }
}

ion-router-outlet.login:deep .ion-page {
  background: var(--background);
  background-size: cover;

  ion-content {
    --background: none;

    --webkit-scrollbar {
      width: 8px;
    }
    --webkit-scrollbar-track {
      background-color: transparent;
      margin-right: 60px;
      border-radius: 10px;
    }
    --webkit-scrollbar-thumb {
      background-color: #ededf0;
      border-radius: 10px;
      height: 140px;
    }
  }
}
</style>
