import { AuthStore } from '@/pinia/authStore'
import { ConfigurationStore } from '@/pinia/configStore'

import { PaginatedResult, isPaginatedResult } from './lib/pagination-result'
import './lib/typed-fetch'
import { DocumentDto } from './models/document.model'
import { EventDto } from './models/event.model'
import { JobApplicationDto, JobApplicationState } from './models/job-application.model'
import { JobDto } from './models/job.model'
import { LimitedJobDto } from './models/limited-job.model'

type PaginationOptions = Partial<{
  size: number
  offset: number
}>

type LeadingSlash = `/${string}`

export class APIService {
  getBaseUrl: () => string | undefined
  getIdToken: () => string | undefined
  getTenantId: () => string | undefined

  // Should not have a trailing slash!
  basePath: LeadingSlash = '/api/ui/v5'

  constructor(authStore: () => AuthStore, configStore: () => ConfigurationStore) {
    this.getBaseUrl = () => configStore().getEnv().proxy?.general.apiBaseUrl + this.basePath
    this.getTenantId = () => configStore().getOrganizationId() ?? undefined
    this.getIdToken = () => authStore().getIdToken()
  }

  public async getJobs(options?: PaginationOptions): Promise<PaginatedResult<JobDto>> {
    return this.fetcher('/projects', options).then(res => res.json())
  }

  public async getJob(jobId: string): Promise<JobDto> {
    return this.fetcher(`/projects/${jobId}`).then(res => res.json())
  }

  public async getJobDocuments(jobId: string, options?: PaginationOptions): Promise<PaginatedResult<DocumentDto>> {
    return this.fetcher(`/projects/${jobId}/documents`, options).then(res => res.json())
  }

  public async getJobJobApplications(
    jobId: string,
    options?: { state?: JobApplicationState } & PaginationOptions
  ): Promise<PaginatedResult<JobApplicationDto>> {
    return this.fetcher(`/projects/${jobId}/candidates`, options).then(res => res.json())
  }

  public async getJobApplications(
    options?: {
      state?: JobApplicationState
      sortBy?: 'updated_at' | 'created_at'
      sortOrder?: 'asc' | 'desc'
    } & PaginationOptions
  ): Promise<PaginatedResult<JobApplicationDto>> {
    return this.fetcher('/candidates', options).then(res => res.json())
  }

  public async getJobApplication(jobApplicationId: string): Promise<JobApplicationDto> {
    return this.fetcher(`/candidates/${jobApplicationId}`).then(res => res.json())
  }

  public async getJobApplicationDocuments(
    jobApplicationId: string,
    options?: PaginationOptions
  ): Promise<PaginatedResult<DocumentDto>> {
    return this.fetcher(`/candidates/${jobApplicationId}/documents`, options).then(res => res.json())
  }

  public async getEvents(options?: PaginationOptions): Promise<PaginatedResult<EventDto>> {
    return this.fetcher('/events', options).then(res => res.json())
  }

  public async getEvent(eventId: string): Promise<EventDto> {
    return this.fetcher(`/events/${eventId}`).then(res => res.json())
  }

  public async getInterviews(options?: PaginationOptions): Promise<PaginatedResult<EventDto>> {
    return this.fetcher('/interviews', options).then(res => res.json())
  }

  public async getSidebarJobs(jobIds: string[], options?: PaginationOptions): Promise<PaginatedResult<LimitedJobDto>> {
    const queryParams = {
      ...options,
      ids: jobIds
    }

    return this.fetcher('/sidebar', queryParams).then(res => res.json())
  }

  /**
   * This is an improved fetch function that uses the fetch API under the hood. It is made more type-safe. On paginated
   * results, it will also add a next function to the response object. This function can be used to fetch the next
   * page.
   *
   * @param path The portal api path. Excluding any versioning or base path.
   * @param queryParameters The query parameters object.
   */
  private async fetcher<ResponseType = any>(
    path: LeadingSlash,
    queryParameters?: Record<string, any>,
    init?: TypedRequestInit
  ): Promise<TypedResponse<ResponseType>> {
    const url = new URL(this.getBaseUrl() + path)

    console.debug('queryParameters', queryParameters)

    if (queryParameters) {
      Object.entries(queryParameters).forEach(([key, value]) => {
        if (value === undefined) return
        url.searchParams.set(key, value)
      })
    }

    console.debug('fetching api service', url.toString())

    const updatedInit: TypedRequestInit = {
      ...init,
      headers: {
        ...init?.headers,
        ...this.getRequestHeaders()
      }
    }

    const res = await fetch(url.toString(), updatedInit)

    // When the response type includes the pagination object, we assign the next function to the response object.
    if (isPaginatedResult(res)) {
      res.next = async () => {
        return this.fetcher<PaginatedResult<ResponseType>>(
          path,
          { ...queryParameters, offset: res.pagination.offset + res.pagination.size },
          updatedInit
        )
      }
    }

    return res
  }

  private getRequestHeaders(): TypedHeaders {
    if (!this.getIdToken() || !this.getTenantId()) {
      throw new Error('IdToken or TenantId is not set')
    }

    return {
      IdToken: `${this.getIdToken()}`,
      'X-Tenant': `${this.getTenantId()}`
    }
  }
}
