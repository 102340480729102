import { useAuthStore } from '../pinia/authStore'
import { useConfigurationStore } from '../pinia/configStore'

import api from '@/config/api'

interface FetcherOptions {
  requestInit?: RequestInit
  baseUrlOverwrite?: string
  apiType?: keyof typeof api.apiType
  version?: 'v4' | 'v5' | 'portal/ui'
  fakeIdToken?: string
}

const DefaultFetcherOptions: FetcherOptions = {
  version: 'v4',
  apiType: 'portal'
}

/**
 * By default the content-type is set to `application/json`. If you want to send a different content-type, you can set
 * it inside the init object.
 *
 * @param endpoint The endpoint of the API. This one automatically gets prefixed with the API base URL.
 * @param options The options for the fetcher. If nothing is set, the default options are used. The defaults are
 *   `version: v4`, `apiType: portal`
 * @returns The response of the fetch function
 */
export const fetcher = async (endpoint: string, options: FetcherOptions = DefaultFetcherOptions) => {
  const apiTypeKey = options.apiType ?? DefaultFetcherOptions.apiType ?? 'portal'
  const version = options.version ?? DefaultFetcherOptions.version
  const apiType = api.apiType[apiTypeKey]

  const authStore = useAuthStore()
  const configStore = useConfigurationStore()

  const env = configStore.getEnv()
  const locale = configStore.getLocale()
  const talkEndpoint = configStore.getTalkUrl()
  const headers = await authStore.getRequestHeaders(apiType)

  const tenantId = configStore.getOrganizationId()

  if (!tenantId) throw new Error('No tenant configuration found while fetching data from the API')

  // ... the deeper down the nested object is added the more likely it overwrites the previous value
  const modifiedInit = {
    ...options.requestInit,
    headers: {
      // If the content-type is not set inside the init object, set it to JSON
      'Content-Type': 'application/json',
      'Accept-Language': locale,
      ...(tenantId ? { 'x-tenant': tenantId } : {}),
      ...options.requestInit?.headers,
      ...headers,
      ...(options.fakeIdToken ? { IdToken: options.fakeIdToken } : {})
    }
  } as RequestInit

  console.log('modified init ', modifiedInit)

  if (!env.proxy) throw new Error('No proxy configured while fetching data from the API')

  let url = ''
  if (apiType === api.apiType.portal) {
    let versionPath: string = ''

    if (version === 'v4') {
      versionPath = 'api/v4/'
    } else if (version === 'v5') {
      versionPath = 'api/v5/'
    } else if (version === 'portal/ui') {
      versionPath = 'api/ui/v5/'
    }

    url =
      options.baseUrlOverwrite ?? env.proxy.general.apiBaseUrl + (`/${versionPath}` + endpoint).replace(/\/\//g, '/')
  } else if (apiType === api.apiType.talk) {
    url = `${talkEndpoint}/${endpoint}`
  }

  try {
    const res = await fetch(url, modifiedInit)

    if (!res.ok) {
      console.error(await res.json())
      throw new Error(`Request to ${url} failed with status code ${res.status}`)
    }

    return res
  } catch (error) {
    throw error
  }
}
