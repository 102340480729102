export interface Metadata {
  id: string

  additional_data: Record<string, any>

  created_at: Date
  created_by: string
  updated_at: Date
  updated_by: string

  tenant_id: string
  references: Record<string, string>
}

export interface Address extends Metadata {
  country_code: string
  city: string
  postal_code?: string
  subdevision_code?: string
  street?: string
  street_number?: string
  latitude?: number
  longitude?: number

  person?: Person[]
  job?: Job[]
}

export interface Person extends Metadata {
  first_name?: string
  last_name?: string
  email?: string
  email_secondary?: string[]
  gender: Gender
}

export interface JobPerson extends Metadata {
  person_id: string
  job_id: string
  role: string

  job?: Job
  person?: Person
}

export interface Company extends Metadata {
  hub_id?: string
  name: string
}

export type DocumentType = 'photo' | 'cv' | 'other'
export type Gender = 'male' | 'female' | 'non_binary'
export type EventType = 'interview' | 'meeting' | 'deadline' | 'other'
export type EventState = 'planned' | 'in_progress' | 'cancelled'
export type SkillType = 'industry_knowledge' | 'general_skill'
export type Rejecter = 'candidate' | 'recruiter' | 'client'
export type StepGroup = 'sourcing' | 'screening' | 'selection' | 'evaluation' | 'placement'

export interface Step extends Metadata {
  name: string
  description?: string
  group: StepGroup

  job_application_progress?: JobApplicationProgress[]
  path_step?: PathStep[]
}

export interface PathStep extends Metadata {
  path_id: string
  step_id: string
  index: number

  path?: Path
  step?: Step
}

export interface Path extends Metadata {
  name: string

  job?: Job[]
  path_step?: PathStep[]
}

export interface Document extends Metadata {
  filename: string
  type: DocumentType
  url: string
  size_bytes: number
  mime_type: string

  candidate_document?: CandidateDocument[]
  job_document?: JobDocument[]
}

export interface JobDocument extends Metadata {
  job_id: string
  document_id: string

  job?: Job
  document?: Document
}

export interface CandidateDocument extends Metadata {
  candidate_id: string
  document_id: string

  candidate?: Candidate
  document?: Document
}

export interface Candidate extends Metadata {
  person_id: string
  gdpr: Date
  cv_data?: Record<string, any>
  free_text?: Record<string, any>

  education?: Education[]
  language_skill?: LanguageSkill[]
  candidate_skill?: CandidateSkill[]
  candidate_document?: CandidateDocument[]
  job_application?: JobApplication[]
  position?: Position[]
}

export interface Position extends Metadata {
  candidate_id: string
  company_id: string
  name?: string
  start_date?: Date
  end_date?: Date

  candidate?: Candidate
  company?: Company
}

export interface Education extends Metadata {
  candidate_id: string
  hub_id?: string
  institution_name?: string
  degree?: string
  grade?: number
  start_date?: Date
  end_date?: Date

  candidate?: Candidate
}

export interface LanguageSkill extends Metadata {
  candidate_id: string
  language_code: string
  proficiency_level: number

  candidate?: Candidate
}

export interface CandidateSkill extends Metadata {
  candidate_id: string
  skill_id: string
  months_of_experience?: number

  candidate?: Candidate
  skill?: Skill
}

export interface Skill extends Metadata {
  name: string
  sovren_id?: string
  type: SkillType

  candidate_skill?: CandidateSkill[]
}

export interface JobApplication extends Metadata {
  candidate_id: string
  job_id: string
  currency_code?: string
  current_hourly_rate?: number
  expected_hourly_rate_from?: number
  expected_hourly_rate_to?: number
  current_salary?: number
  expected_salary_from?: number
  expected_salary_to?: number
  expected_weekly_remote_days?: number
  contract_salary?: number
  placement_salary?: number
  free_text?: string

  candidate?: Candidate
  job?: Job

  job_application_progress?: JobApplicationProgress[]
  job_application_event?: JobApplicationEvent[]
  rejection?: Rejection[]
}

export interface Rejection extends Metadata {
  job_application_id: string
  rejection_reason_id?: string
  rejected_by: Rejecter
  comment?: string
  timestamp: Date

  job_application?: JobApplication
  rejection_reason?: RejectionReason
}

export interface RejectionReason extends Metadata {
  name: string
  description?: string
  locales: Record<string, string>

  rejection?: Rejection[]
}

export interface JobApplicationEvent extends Metadata {
  event_id: string
  job_application_id: string

  event?: Event
  job_application?: JobApplication
}

export interface JobApplicationProgress extends Metadata {
  job_application_id: string
  step_id: string
  timestamp: Date

  job_application?: JobApplication
  step?: Step
}

export interface Job extends Metadata {
  name: string
  company_id?: string
  path_id: string
  vacancy_count: number
  job_starting_date?: Date
  job_ending_date?: Date
  closing_date?: Date
  address_id?: string
  salary_from?: number
  salary_to?: number
  salary_currency_code?: string
  max_weekly_remote_days?: number
  free_text?: string

  company?: Company
  path?: Path
  address?: Address

  job_application?: JobApplication[]
  job_document?: JobDocument[]
  job_person?: JobPerson[]
}

const allModels = {
  Address: 'address',
  Candidate: 'candidate',
  CandidateDocument: 'candidate_document',
  CandidateSkill: 'candidate_skill',
  Company: 'company',
  Document: 'document',
  Education: 'education',
  Event: 'event',
  Job: 'job',
  JobApplication: 'job_application',
  JobApplicationEvent: 'job_application_event',
  JobApplicationProgress: 'job_application_progress',
  JobDocument: 'job_document',
  JobPerson: 'job_person',
  LanguageSkill: 'language_skill',
  Path: 'path',
  PathStep: 'path_step',
  Person: 'person',
  Position: 'position',
  Rejection: 'rejection',
  RejectionReason: 'rejection_reason',
  Skill: 'skill',
  Step: 'step'
} as const

export function getDatabaseNameFromModelName(model: ModelName): DatabaseName {
  return allModels[model]
}

export function getModelNameFromDatabaseName(database: DatabaseName): ModelName {
  return Object.keys(allModels).find(model => allModels[model as keyof typeof allModels] === database) as ModelName
}

export type ModelName = keyof typeof allModels
export type DatabaseName = (typeof allModels)[ModelName]
