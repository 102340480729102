import { api } from '@/config/api-service.config'
import { LimitedJobDto } from '@/services/api/models/limited-job.model'

type ProjectHistory = LimitedJobDto & {
  fecthedAt: Date
}

/**
 * Moves the project with the given id to the front of the history. If the project is already in the history, it is
 * removed and added to the front. If the history is full, the last project is removed.
 *
 * @param array The array to move the project in.
 * @param id The id of the project to move to the front.
 * @param maxLength The maximum length of the history.
 */
function shift<T extends { id: string }>(array: T[], newItem: T, maxLength: number) {
  const index = array.findIndex(item => item.id === newItem.id)

  if (index !== -1) {
    // The project is already in the history, so we remove it and add it to the front
    array.splice(index, 1)
  } else if (array.length >= maxLength) {
    // The history is full, so we remove the last project
    array.pop()
  }

  array.unshift(newItem)
}

/**
 * This composables function can be used to track the last opened projects.
 *
 * @param memoryLength You can specify the number of projects to remember. The default is `5`.
 */
export function useProjectHistory(memoryLength: number = 5) {
  /**
   * This is a reference to the local storage key `project-history`. It is used to store the last opened projects.
   */
  const historicProjects = useLocalStorage<ProjectHistory[]>('project-history', [])

  /**
   * An async function to push a project to the history. If the project is already in the history, it is removed and
   * added to the front of the `historicProjects` stack. If the history is full, the last project is removed.
   *
   * @param id The id of the project to push to the history and fetch from the server.
   */
  async function pushProjectToHistory(id: string) {
    if (historicProjects.value.some(project => project.id === id)) {
      console.info(`Project '${id}' already in history`)
      return
    }

    const paginatedResult = await api.getSidebarJobs([id])

    if (paginatedResult.data === undefined || paginatedResult.data.length === 0) {
      throw new Error('No projects found')
    }

    const newProjectHistory: ProjectHistory = {
      ...paginatedResult.data[0],
      fecthedAt: new Date()
    }

    shift<ProjectHistory>(historicProjects.value, newProjectHistory, memoryLength)
  }

  /**
   * Clear the history.
   */
  function clear() {
    historicProjects.value = []
  }

  return {
    historicProjects,
    pushProjectToHistory,
    clear
  }
}
