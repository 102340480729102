import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useLoadingStore = defineStore('loading', () => {
  const loadingCounter = ref(0)

  const isLoading = computed(() => loadingCounter.value > 0)

  function start() {
    loadingCounter.value++
  }

  function stop() {
    if (loadingCounter.value > 0) {
      loadingCounter.value--
    }
  }

  return {
    isLoading,
    start,
    stop
  }
})
