<script lang="ts" setup>
import { LocalizedString, localizeValue } from '@/services/api/models/localized-string.model'

const props = defineProps({
  value: {
    type: [String, Object] as PropType<string | LocalizedString | undefined>,
    default: undefined
  },
  showAutoLocalization: {
    type: Boolean,
    default: true
  },
  fallbackValue: {
    type: String,
    default: '<Missing Label>'
  }
})

const { value, showAutoLocalization, fallbackValue } = toRefs(props)

const localizationResult = computed(
  () =>
    localizeValue(value.value, fallbackValue.value) ?? {
      value: fallbackValue.value,
      localizationKey: undefined,
      autoGenerated: false
    }
)

const localizedValue = computed(() => localizationResult.value?.value)
const localizationKey = computed(() => localizationResult.value?.localizationKey)
const isAutoGenerated = computed(() => localizationResult.value?.autoGenerated ?? false)
</script>

<template>
  <span>{{ localizedValue }}</span>
</template>
