<template>
  <ion-card
    class="emmytalk h-full"
    :class="{
      'fixed-emmytalk': !isOverlay,
      'top-border': isOverlay,
      'overlay-border': isOverlay,
      'max-height': !isOverlay && !isPage('Emmytalk'),
      'max-height-emmyTalk': !isOverlay && isPage('Emmytalk'),
      emmytalkFullscreen: emmytalkFullscreen,
      shadow: isOverlay
    }"
  >
    <ion-card-header v-if="sidebarOpen || !isOverlay" class="desktop" @click="toggleSidebar">
      <div>
        <ion-card-title>
          <svg class="icon" width="23" height="17" viewBox="0 0 23 17" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M20.4124 0.115479H2.5874C1.40146 0.115479 0.431152 1.14449 0.431152 2.40216V14.5978C0.431152 15.4363 0.826465 16.1604 1.50928 16.5796C1.83271 16.7702 2.22803 16.8845 2.5874 16.8845C2.94678 16.8845 3.30615 16.7702 3.66553 16.5796L6.82803 14.6359C6.97178 14.5597 7.11553 14.5216 7.25928 14.5216H20.3765C21.5624 14.5216 22.5327 13.4926 22.5327 12.2349V2.40216C22.5687 1.14449 21.5983 0.115479 20.4124 0.115479ZM21.3108 12.2349C21.3108 12.7685 20.9155 13.1877 20.4124 13.1877H7.29521C6.93584 13.1877 6.54053 13.302 6.21709 13.4926L3.05459 15.4363C2.76709 15.5887 2.44365 15.5887 2.15615 15.4363C1.86865 15.2838 1.7249 14.9789 1.7249 14.6359V2.40216C1.7249 1.8686 2.12022 1.44938 2.62334 1.44938H20.4483C20.9515 1.44938 21.3468 1.8686 21.3468 2.40216V12.2349H21.3108Z"
            />
            <path
              d="M16.064 4.68884H6.43276C6.07339 4.68884 5.78589 4.99373 5.78589 5.37485C5.78589 5.75596 6.07339 6.06085 6.43276 6.06085H16.1C16.4593 6.06085 16.7468 5.75596 16.7468 5.37485C16.7468 4.99373 16.4234 4.68884 16.064 4.68884Z"
            />
            <path
              d="M14.5546 8.99545H6.43276C6.07339 8.99545 5.78589 9.30034 5.78589 9.68146C5.78589 10.0626 6.07339 10.3675 6.43276 10.3675H14.5906C14.95 10.3675 15.2375 10.0626 15.2375 9.68146C15.2375 9.30034 14.914 8.99545 14.5546 8.99545Z"
            />
          </svg>
          <span>EmmyTalk</span>
        </ion-card-title>
      </div>
      <div>
        <ion-icon v-if="isOverlay && !sidebarOpen" :icon="chevronUpOutline"></ion-icon>
        <ion-icon v-if="isOverlay && sidebarOpen" :icon="chevronDownOutline"></ion-icon>
      </div>
    </ion-card-header>

    <ion-card-header v-if="sidebarOpen" class="mobile" @click="toggleMobileSidebar">
      <div>
        <ion-card-title>
          <span>EmmyTalk</span>
        </ion-card-title>
      </div>
      <div class="display-flex">
        <ion-icon v-if="isOverlay && sidebarOpen && false" :icon="resize" @click.stop="toggleFullscreen" />

        <ion-icon v-if="isOverlay && !sidebarOpen" :icon="chevronUpOutline"></ion-icon>
        <ion-icon v-if="isOverlay && sidebarOpen" :icon="chevronDownOutline"></ion-icon>
      </div>
    </ion-card-header>
    <div v-if="sidebarOpen || !isOverlay" class="sidebar-container">
      <Segments :is-overlay="isOverlay" />
    </div>
  </ion-card>

  <!-- Fab Button -->
  <div
    v-if="!isPage('Emmytalk') && !isPage('Login')"
    :class="{ 'is-home': isPage('Home') }"
    class="emmytalk-btn"
    @click="toggleSidebar"
  >
    <FabIcon />
  </div>
</template>

<script>
import { IonCard, IonCardHeader, IonCardTitle, IonIcon } from '@ionic/vue'
import { isPlatform } from '@ionic/vue'
import {
  chevronDownOutline,
  chevronUpOutline,
  close,
  createOutline,
  ellipsisHorizontalOutline,
  resize
} from 'ionicons/icons'
import { storeToRefs } from 'pinia'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import { useTalkStore } from '@/pinia/talkStore'

import FabIcon from '@/assets/fab.svg'

import Segments from './Segments.vue'

export default {
  name: 'EmmytalkSidebar',
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonIcon,
    Segments,
    FabIcon
  },
  props: { isOverlay: Boolean, isMobile: Boolean },
  setup(props) {
    const store = useStore()
    const talkStore = useTalkStore()
    const route = useRoute()

    const { isOverlaySidebarOpen: sidebarOpen, isFullScreen: emmytalkFullscreen } = storeToRefs(talkStore)

    function closeSidebar() {
      talkStore.closeOverlaySidebar()
      talkStore.closeAllOverlayTalks()
    }

    function toggleSidebar() {
      talkStore.toggleOverlaySidebar()

      if (route.fullPath.includes('candidates')) {
        talkStore.changeActiveSegment('candidates')
        const candidateProject = store.getters.getCandidateApplicationProject(route.params.id)

        if (candidateProject) {
          const jobApplication = candidateProject.jobApplications.find(
            jobApplication => jobApplication.id === route.params.id
          )
          if (jobApplication) {
            var payloadCandidate = {
              id: route.params.id,
              message_type: 'candidate',
              talkType: 'standard',
              title: jobApplication.candidateName,
              applicationId: route.params.id,
              projectId: candidateProject.id
            }

            if (props.isOverlay && route.params.id !== undefined) {
              if (sidebarOpen.value) {
                talkStore.openOverlayTalk(payloadCandidate)
              }
            } else {
              talkStore.openTalk(payloadCandidate)
            }
          }
        }
      }

      if (route.fullPath.includes('projects')) {
        talkStore.changeActiveSegment('project')

        const project = store.getters.getProject(route.params.id)
        if (project) {
          var payloadProject = {
            id: route.params.id,
            message_type: 'project',
            talkType: 'standard',
            title: project.name,
            projectId: project.id
          }

          if (props.isOverlay && route.params.id !== undefined) {
            if (sidebarOpen.value) {
              talkStore.openOverlayTalk(payloadProject)
            }
          } else {
            talkStore.openTalk(payloadProject)
          }
        }
      }
    }

    function toggleMobileSidebar() {
      talkStore.openOverlaySidebar()

      if (route.fullPath.includes('candidates')) {
        talkStore.changeActiveSegment('candidates')
      }

      if (route.fullPath.includes('projects')) {
        talkStore.changeActiveSegment('project')
      }
    }

    function toggleFullscreen() {
      talkStore.toggleFullScreen()
    }

    return {
      closeSidebar,
      close,
      chevronDownOutline,
      chevronUpOutline,
      resize,
      ellipsisHorizontalOutline,
      createOutline,
      toggleSidebar,
      toggleMobileSidebar,
      toggleFullscreen,
      sidebarOpen,
      emmytalkFullscreen
    }
  },
  data: () => {
    return {
      emmysoftIcon
    }
  },
  data: function () {
    return { isMinimized: true }
  },
  computed: {
    isFixed: function () {
      return true
    }
  },
  watch: {
    isMinimized: function (val) {
      console.debug(val)
    }
  },
  methods: {
    spacing() {
      return isPlatform('iphone')
    },
    isPage(...pageNames) {
      return pageNames.includes(this.$route.name)
    }
  }
}
</script>

<style scoped lang="scss">
.sidebar {
  overflow: hidden;
  border-radius: 10px 10px 0 0 !important;
}

ion-card-title {
  display: flex;
  align-items: center;

  svg {
    fill: #ffffff;
  }

  span {
    margin-left: 8px;
  }
}

@media (max-width: 899px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
  }

  ion-card-title {
    display: flex;
    align-items: center;

    svg {
      fill: #ffffff;
    }

    span {
      margin-left: 0;
    }
  }

  .overlay-border {
    padding-bottom: 0 !important;
  }
}

@media (min-width: 900px) {
  .desktop {
    display: flex;
  }

  .mobile {
    display: none;
  }

  .center-icon {
    margin-left: 12px;
  }

  ion-icon {
    height: 26px;
    width: 26px;
  }
}

ion-card {
  box-shadow: none;
  --background: var(--primary);
  border-radius: 10px;
  --color: var(--font-white);
}

@media (max-width: 899px) {
  ion-card {
    box-shadow: none;
    --background: var(--primary);
    border-radius: 10px;
    --color: var(--font-white);
    width: 100%;
    max-height: 100vh;
    margin-bottom: 10px;
  }

  ion-icon {
    height: 26px;
    width: 26x;
  }
}

@media (min-width: 1200px) {
  .is-home {
    display: none !important;
  }
}

.fixed-emmytalk {
  margin: 0;
  overflow: hidden;
  border-radius: 10px !important;
  // border-bottom-left-radius: 0px !important;
  // border-bottom-right-radius: 0px !important;
}

@media (max-width: 1201px) {
  .fixed-emmytalk {
    display: none !important;
  }
}

ion-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-left: 4px;
  margin-right: 4px;
}

ion-card-title,
ion-card-subtitle {
  color: var(--font-white);
}

ion-card-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

ion-card-subtitle {
  margin-top: 3px;
  font-size: 14px;
  line-height: 17px;
}

ion-icon {
  padding-left: 18px;
}

.overlay-border {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 0;
}

.emmytalkFullscreen {
  border-radius: 0;
}

@media (max-width: 899px) {
  .center-icon {
    margin-left: 6px;
  }

  .overlay-border {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 10px;
  }
}

.max-height-emmyTalk {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.display-flex {
  display: flex;
}

.iosSpacing {
  padding-top: 48px;
}

.emmytalk-btn {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 16px;
  right: 16px;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  border: 3px solid var(--circle-color-emmytalk);
  background: var(--fab-button-background);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all ease-in-out 0.3s;

  &:hover {
    background-color: var(--fab-button-background-hover);
  }
}

.sidebar-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.top-border {
  overflow: hidden;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.shadow {
  box-shadow: 0 2px 10px rgba(black, 0.25);
}
</style>
