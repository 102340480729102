import { defineStore } from 'pinia'

import { FirebaseOptions, initializeApp, deleteApp, FirebaseApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

import { isPlatform } from '@ionic/core'
// import { Capacitor } from '@capacitor/core'
import { PushNotifications } from '@capacitor/push-notifications'

import { FCM } from '@emmysoft-gmbh/fcm-multi-tenant'
import { useLocalStorage } from '@vueuse/core'
import { useConfigurationStore } from './configStore'
import { useAuthStore } from './authStore'
import { PushNotificationManager } from '@emmysoft-gmbh/push-notification-service'

import { startTransaction } from '@sentry/vue'

import { ref } from 'vue'
import { i18n } from '@/config/i18n'

let sharedPushNotificationManager: PushNotificationManager | undefined

export const useFirebaseStore = defineStore('firebase', () => {
  // ===========
  // State
  // ===========

  var firebaseApp: FirebaseApp | null = null

  /**
   * The firebase cloud messaging token associated with the current device.
   */
  const fcmToken = useLocalStorage('fcmToken', null as string | null)

  /**
   * A boolean indicating whether the store has been initialised before.
   */
  const isInitialized = ref(false)

  // ===========
  // Actions
  // ===========

  /**
   * Initializes Firebase on both web and native platforms.
   */
  async function initialize() {
    console.log('🔥 Initializing Firebase...')

    if (isInitialized.value) {
      console.log('🔥 Firebase already initialized')
      return
    }

    const configStore = useConfigurationStore()

    const env = configStore.getEnv()

    if (isPlatform('desktop')) {
      console.log('🔥 Web platform detected')

      // Request permission to receive notifications on desktop
      const permission = await Notification.requestPermission()

      // if permission is not granted, do not register the service worker
      if (permission !== 'granted') {
        console.debug('🔥 Permission not granted, skipping FCM initialization')
        return
      }

      console.log('🔥 Push Notification Permission granted')

      const firebaseConfig: FirebaseOptions | undefined = env.proxy?.firebase.web

      if (!firebaseConfig) {
        console.error('🔥 No firebase configuration found, skipping FCM initialization')
        return
      }

      firebaseApp = initializeApp(firebaseConfig)
      const messaging = getMessaging(firebaseApp)

      const serviceWorker = await navigator.serviceWorker.register('/sw.js', {
        scope: '/portal/firebase-cloud-messaging-push-scope'
      })

      // Whenever a new message arrives sync with the backend to get the latest notifications
      // to display inside the notifications list
      onMessage(messaging, payload => {
        this.$store.dispatch('GET_ALL_NOTIFICATIONS')
      })

      const vapidPublicKey = env.proxy?.firebase.web.vapidPublicKey

      if (!vapidPublicKey) {
        console.error('🔥 No VAPID public key found, skipping FCM initialization')
        return
      }

      try {
        const token = await getToken(messaging, {
          vapidKey: vapidPublicKey,
          serviceWorkerRegistration: serviceWorker
        })

        console.log('🔥 Received token: ' + token)

        if (!token) {
          console.error('🔥 No token received, skipping FCM initialization')
          return
        }

        fcmToken.value = token
        sendDeviceToken()
      } catch (error) {
        console.error('🔥 Error getting token:', error)
      }
    } else {
      // Mobile
      console.log('🔥 Mobile platform detected')

      let firebaseConfig: Record<string, any> | undefined

      if (isPlatform('ios')) {
        firebaseConfig = env.proxy?.firebase.ios
      } else if (isPlatform('android')) {
        firebaseConfig = env.proxy?.firebase.android
      } else {
        throw new Error('🔥 Unsupported platform for receiving push notifications')
      }

      if (!firebaseConfig) {
        console.error('🔥 No firebase configuration found, skipping FCM initialization')
        return
      }

      const b = await FCM.echo({ value: 'Hello from FCM plugin' })
      console.log('🔥 Returned from FCM.echo: ' + b.value)

      console.log('🔥 Initializing FCM mobile plugin')
      // Needs to be a plain object otherwise the plugin will not work
      // Do not wait for this call to finish as the following code will attach listeners
      // that will be called when the native code has finished initializing
      FCM.configure(JSON.parse(JSON.stringify(firebaseConfig)))
      console.log('🔥 FCM mobile plugin call send to native code')

      const addListeners = async () => {
        console.log('🔥 Adding listeners')

        // On success, we should be able to receive notifications
        await PushNotifications.addListener('registration', token => {
          console.log('🔥 Push registration success, token: ' + token.value)
          fcmToken.value = token.value
          sendDeviceToken()
        })

        // Some issue with our setup and push will not work
        await PushNotifications.addListener('registrationError', error => {
          console.log('🔥 Error on registration: ' + JSON.stringify(error))
        })

        await PushNotifications.addListener('pushNotificationReceived', notification => {
          console.log('🔥 Push received: ' + JSON.stringify(notification))
          this.$store.dispatch('GET_ALL_NOTIFICATIONS')
        })

        // Method called when tapping on a notification
        await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
          console.log('🔥 Push action performed: ' + JSON.stringify(notification))
        })
      }

      const registerNotifications = async () => {
        console.log('🔥 Registering notifications')

        let permStatus = await PushNotifications.checkPermissions()

        console.log('🔥 Push notifications permissions: ' + JSON.stringify(permStatus))

        if (permStatus.receive === 'prompt') {
          permStatus = await PushNotifications.requestPermissions()
        }

        if (permStatus.receive !== 'granted') {
          throw new Error('🔥 User denied push notifications!')
        }

        console.log('🔥 User granted push notifications!')
        await PushNotifications.register()
      }

      await addListeners()
      await registerNotifications()
    }

    isInitialized.value = true

    console.log('🔥 Initialized')
  }

  /**
   * Sends the device token to the backend. The device token then gets used to send push notifications to the device.
   */
  async function sendDeviceToken() {
    console.log('🔥 Sending push device token to backend...')

    // Create a new sentry transaction to track the time it takes to send the device token
    // const transaction = startTransaction({
    //   op: 'sendDeviceToken',
    //   name: 'Send device token'
    // })

    const token = fcmToken.value
    if (!token) {
      console.error('🔥 No token received, skipping FCM initialization')
      return
    }

    if (!useAuthStore().isAuthenticated) {
      console.error('🔥 Trying to send device token while no user is logged in, skipping FCM initialization')
      sharedPushNotificationManager = undefined
      return
    }

    const env = useConfigurationStore().getEnv()

    const pushNotificationBaseUrl = env.proxy?.general.pushNotificationBaseUrl

    if (!pushNotificationBaseUrl) {
      console.error('🔥 No push notification base url found, skipping FCM initialization. EmmyTalk v2')
      return
    }

    sharedPushNotificationManager = new PushNotificationManager({
      idToken: useAuthStore().getIdToken()!,
      tenantId: String(env.proxy?.general.adminConsoleId),
      baseUrl: pushNotificationBaseUrl
    })

    // Variable can be used to get the platform the app is running on
    // const platform = Capacitor.getPlatform()

    const locale = i18n.global.locale.value

    // TODO: Add language to the device token
    const response = await sharedPushNotificationManager.createDevice({
      _id: token,
      language: locale
    })

    // transaction.finish()

    console.log('🔥 Push Device token sent to backend.')

    return response
  }

  /**
   * Deinitialize firebase from the app. This will prevent the app from receiving push notifications.
   */
  async function deinitialize() {
    console.log('🔥 Deinitializing Firebase...')

    if (!isInitialized.value) {
      console.log('🔥 Firebase already deinitialized')
      return
    }

    fcmToken.value = null

    if (isPlatform('desktop')) {
      if (firebaseApp) deleteApp(firebaseApp)
    } else {
      await FCM.reset()
    }

    if (sharedPushNotificationManager && fcmToken.value) {
      const response = await sharedPushNotificationManager.deleteDevice(fcmToken.value)
    }

    isInitialized.value = false

    console.log('🔥 Deinitialized Firebase')
  }

  return { fcmToken, initialize, deinitialize, firebaseApp }
})
