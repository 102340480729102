import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import '@/styles/tailwind.css'

// import configurations

import store from './store'
import { createPinia } from 'pinia'

const pinia = createPinia()

import { IonicVue } from '@ionic/vue'

/* Core CSS required for Ionic components to work properly */
import '@/theme/globals.scss'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/core.css'
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

import { useConfigurationStore } from './pinia/configStore'
import { useEmmyTalk } from './views/Emmy/composables/useEmmyTalk'
import { i18n } from './config/i18n'

async function init() {
  const app = createApp(App)

  app.use(IonicVue, { mode: 'md' })
  app.use(pinia)
  app.use(store)
  app.use(i18n)

  // Initialize the configuration store before the router
  const configStore = useConfigurationStore()
  await configStore.init()

  const env = configStore.getEnv()

  app.use(router)

  router.isReady().then(() => {
    app.mount('#app')
  })

  const { emmyTalkInit } = useEmmyTalk()
  emmyTalkInit()
}

// Load the environment configuration file
// Has to be the first js file that gets loaded
const scriptElement = document.createElement('script')
scriptElement.type = 'text/javascript'
scriptElement.src = '/config.js'

document.head.appendChild(scriptElement)

// Only after the configuration file has been loaded, we can initialize the app
scriptElement.onload = () => {
  init()

  import(/* @vite-ignore */ window.VUE_APP_WEB_COMPONENT_URL)
    .then(({ register }) => {
      register()
    })
    .catch(error => {
      console.error('Failed to import the web component:', error)
    })
}
