<template>
  <ion-card
    v-if="message"
    :style="{
      width: fullscreenWidth + 'px',
      borderTopColor: '#02A79F'
    }"
    class="unsetWidth"
  >
    <emmy-talk
      :key="`overlay-talk-${message.candidateId ?? message.projectId}`"
      :locale="locale"
      :id-token="idToken"
      :base-api-url="talkApiUrl"
      :current-user="currentUser"
      :is-mobile="`${isMobile()}`"
      :is-overlay="true"
      :talk-id="message.talkId"
      :title-color="message.color"
      :theme="message.title"
      :talk-type="message.talkType"
      :job-ids="getProjectReferencesArray(message.projectId)"
      :application-ids="getApplicationsReferencesArray(message.candidateId)"
      :tenant-id="tenantId"
      @emmytalk-close="closeMessage"
      @emmytalk-toggle-fullscreen="toggleFullscreen"
    ></emmy-talk>
  </ion-card>
</template>

<script>
import { IonCard } from '@ionic/vue'
import { chevronUpOutline, chevronDownOutline, closeOutline, resize } from 'ionicons/icons'
import { isPlatform } from '@ionic/vue'

import { useStore } from 'vuex'
import { useAuthStore } from '@/pinia/authStore'
import { useConfigurationStore } from '@/pinia/configStore'
import { getEnvironmentVariables } from '@/config/env'
import { useTalkStore } from '@/pinia/talkStore'
import { storeToRefs } from 'pinia'

export default {
  name: 'MessageOverlay',
  components: {
    IonCard
  },
  props: { message: Object, isOverlay: Boolean }, // message is a EmmyTalkReference
  setup: function (props) {
    const store = useStore()
    const talkStore = useTalkStore()

    const {
      isFullScreen: emmytalkFullscreen,
      isMessageFullscreen: messageFullscreen,
      fullscreenWidth
    } = storeToRefs(talkStore)

    const configStore = useConfigurationStore()
    const authStore = useAuthStore()
    const locale = configStore.getLocale()

    const envVar = getEnvironmentVariables()

    function toggleFullscreen() {
      store.dispatch('TOGGLE_MESSAGE_FULLSCREEN', 800)
      talkStore.toggleTalkFullScreen(800)
    }

    function closeMessage() {
      store.dispatch('CLOSE_OVERLAY_MESSAGE', props.message)
      talkStore.closeOverlayTalk(props.message)
    }

    return {
      chevronUpOutline,
      chevronDownOutline,
      closeOutline,
      resize,
      toggleFullscreen,
      closeMessage,
      emmytalkFullscreen,
      messageFullscreen,
      fullscreenWidth,
      isFullscreen: emmytalkFullscreen,
      accessToken: computed(() => authStore.getAccessToken()),
      idToken: computed(() => authStore.getIdToken()),
      tenantId: computed(() => authStore.getTenantId()),
      talkApiUrl: computed(() => envVar.VUE_APP_WEB_COMPONENT_TALK_API_URL),
      currentUser: computed(() => authStore.getUsername()),
      locale
    }
  },
  data: function () {
    return {
      username: String,
      width: window.innerWidth / 2,
      height: window.innerHeight / 2
    }
  },

  methods: {
    spacing() {
      return isPlatform('iphone')
    },

    isMobile() {
      return isPlatform('mobile')
    },

    getProjectReferencesArray(projectId) {
      return [`portal#${projectId}`]
    },
    getApplicationsReferencesArray(applicationId) {
      if (applicationId && applicationId.length > 0) {
        return [applicationId]
      }

      return []
    }
  }
}
</script>

<style scoped lang="scss">
ion-card {
  border-top-style: solid;
  border-top-width: 14px;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 1px 22px rgb(33 96 147 / 20%);
  margin-top: 0;
  margin-bottom: 0;
  width: 400px;
}

@media (max-width: 899px) {
  ion-card {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin: 0;
    width: 100%;
  }
}
</style>
