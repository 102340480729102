import { portalFetcher } from '@/lib/portalFetcher'
import { DatabaseName, getDatabaseNameFromModelName, Job, ModelName } from '@/models/datamodel.model'
import { useAuthStore } from './authStore'
import { useConfigurationStore } from './configStore'
import { useLoadingStore } from './loadingStore'
import { v4 as uuidv4 } from 'uuid'
import { TreeNode } from '@/models/Tree.model'
import _ from 'lodash'
import { JobApplication } from '@/models/Project'

// TODO: Add a method that will automatically try to reconnect to the WebSocket if the connection is lost
// TODO: It should first get a new snapshot of the data through the REST API and then try to reconnect to the WebSocket

/**
 * If that does not work fall back to a periodic polling of the REST API
 */

interface WsRequest {
  id?: string
  type: string
  data: Record<string, any>
}

interface WsAuthRequest extends WsRequest {
  type: 'jwt'
  data: {
    idToken: string
  }
}

interface WsResponse {
  id?: string
  success: boolean
  message?: string
  data: Record<string, any>
}

/**
 * This is the data format of a data update send by the server whenever a change occurs in the database.
 */
interface DataUpdate {
  /**
   * The data that has been updated.
   */
  data: Record<string, any>[]

  /**
   * The id of the data that has been updated.
   */
  id: string

  /**
   * The job that this data update is related to.
   */
  jobRelations: string[]

  /**
   * The model name of the data that has been updated.
   */
  modelName: ModelName

  /**
   * The operation that was performed on the data.
   */
  operation: 'create' | 'update' | 'delete'

  /**
   * An idication on wether this is a pseudo event or not. A pseudo event is triggered when data updates might have an
   * impact on connected data. The client should process these messages and manipulate the datatree accordingly.
   */
  pseudoEvent: boolean
}

export const useDataStore = defineStore('data', () => {
  // ===========
  // Dependencies
  // ===========

  const authStore = useAuthStore()
  const configStore = useConfigurationStore()
  const loadingStore = useLoadingStore()

  // ===========
  // State
  // ===========

  /**
   * The milliseconds to wait for a response from the server before rejecting the promise.
   */
  const requestTimeoutMs = 5000

  /**
   * A flag that indicates if the store is initialized. This will be set to true after the store has been initialized
   * and will be set to false after the store has been deinitialized.
   */
  const isInitalized = ref(false)

  /**
   * The data that is stored in the store. This will be updated when the WebSocket connection sends a broadcast or when
   * the client requests data from the server.
   */
  const data = computed(options => {})

  /**
   * The data model that is used to store the data.
   */
  let dataModelRoot = undefined as TreeNode | undefined

  /**
   * The connected WebSocket. This will be initialized when the store is initialized and will be closed when the store
   * is deinitialized.
   */
  let socket: WebSocket | undefined = undefined

  /**
   * A queue of messages that are hold in memory until a response is received. This is necessary because the WebSocket
   * connection is asynchronous and we need to wait for the response to a request. If the response is not received
   * within a certain time, the promise will be rejected and the message will be removed from the queue.
   */
  let messageResponseWaitingQueue: {
    id: string
    promise: {
      resolve: (value: WsResponse | PromiseLike<WsResponse>) => void
      reject: (reason?: any) => void
    }
    timeout: NodeJS.Timeout
  }[] = []

  // ===========
  // Actions
  // ===========

  /**
   * Initializes the data store. First, it will check if the required dependencies are available. If they are, it will
   * fetch the initial state of data from the server and then attach the listener to the WebSocket connection.
   *
   * @returns
   */
  async function initialize() {
    console.log('📦 Initializing Data Store...')

    if (isInitalized.value) {
      console.log('📦 Data Store already initialized')
      return
    }

    // Check if the user is authenticated
    if (!authStore.isAuthenticated) {
      console.error('📦 User is not authenticated, skipping data store initialization')
      return
    }

    // Check if the tenant configuration is available
    if (!configStore.isConfigured) {
      console.error('📦 Tenant configuration is not available, skipping data store initialization')
      return
    }

    const jobs = computed(() => data.value)

    function getJob(id: string) {
      return computed(() => jobs.value.find(job => job.id === id))
    }

    const paths = computed(() => {
      return data.value.flatMap(job => {
        const path = job.path
        if (!path) return []

        // Sort path steps by index
        path.path_step = path.path_step?.sort((a, b) => a.index - b.index)
      })
    })

    const JobApplications = computed(() => {
      return data.value.flatMap(job => (job.job_application as JobApplication[] | undefined) ?? [])
    })

    function getJobApplicationById(id: string) {
      return computed(() => JobApplications.value.find(jobApplication => jobApplication.id === id))
    }

    function getJobApplicationsByJobId(jobId: string) {
      return computed(
        () => (data.value.find(job => job.id === jobId)?.job_application as JobApplication[] | undefined) ?? []
      )
    }

    const apiBaseUrl = configStore.getEnv().proxy?.general.apiBaseUrl
    if (!apiBaseUrl) {
      console.error('📦 API base URL is not available, skipping data store initialization')
      return
    }

    isInitalized.value = true

    // Fetch the data model from the server
    await fetchDataModel()

    // Can be run in parallel
    fetchData()

    // Remove http(s) from the URL with regex
    const cleanedBaseUrl = apiBaseUrl.replace(/^https?:\/\//, '')

    // Connect to the WebSocket
    socket = new WebSocket(`ws://${cleanedBaseUrl}/ws`)
    socket.onopen = openHandler
    socket.onclose = closeHandler
    socket.onmessage = messageHandler
  }

  /**
   * Resets the store to its initial state
   */
  async function deinitialize() {
    console.log('📦 Deinitializing Data Store...')

    if (!isInitalized.value) {
      console.log('📦 Data Store already deinitialized')
      return
    }

    if (socket) {
      socket.close()
    }

    data.value = []

    isInitalized.value = false
  }

  // ==================
  // WebSocket Handlers
  // ==================

  /**
   * Handles the opening of the WebSocket connection. This will automatically attach the listener to the socket. The
   * listener will send authentication data to the server and will let the server know which data the client is
   * interested in.
   */
  async function openHandler() {
    console.log('📦 [WebSocket] connection opened')
    await attachListener()
  }

  /**
   * Will be called when the WebSocket connection is closed. This will stop all queued requests and reject their
   * promises.
   */
  async function closeHandler() {
    console.log('📦 [WebSocket] connection closed')
    stopAllQueuedRequests()
  }

  /**
   * Will handle incoming messages from the WebSocket connection. If the message is a response to a request, it will
   * resolve the promise that is waiting for the response. If the message is a broadcast, it will handle it
   * accordingly.
   *
   * @param event The incoming message event
   */
  async function messageHandler(event: MessageEvent) {
    const response: WsResponse = JSON.parse(event.data)

    // If the response has an id, it's a response to a request
    if (response.id) {
      const waitingMessage = messageResponseWaitingQueue.find(message => message.id === response.id)

      if (waitingMessage) {
        clearTimeout(waitingMessage.timeout)
        waitingMessage.promise.resolve(response)
        messageResponseWaitingQueue = messageResponseWaitingQueue.filter(message => message.id !== response.id)
        console.log('📦 [WebSocket] response received', response)
      } else {
        console.error('📦 [WebSocket] No waiting message found for response', response)
      }
      return
    } else if (response.message === 'PortalApi') {
      dataUpdateHandler(response.data as DataUpdate)
    } else {
      // If the response has no id, it's a broadcast
      console.log('📦 [WebSocket] broadcast received', response)
    }
  }

  /**
   * The data update handler is responsible for updating the localally stored data when the server sends a broadcast.
   */
  async function dataUpdateHandler(data: DataUpdate) {
    console.log('📦 [WebSocket] data update received', data)

    if (!dataModelRoot) {
      await fetchDataModel()
    }

    updateData(data)
  }

  // ===========
  // Helpers
  // ===========

  /**
   * Will remove all queued requests and reject their promises
   */
  function stopAllQueuedRequests() {
    for (const message of messageResponseWaitingQueue) {
      clearTimeout(message.timeout)
      message.promise.reject(new Error('📦 [WebSocket] Connection closed'))
    }

    messageResponseWaitingQueue = []
  }

  async function fetchData() {
    // loadingStore.start()
    // const response = await portalFetcher('Jobs', { include: '*' })
    // const jobs = (await response.json()) as Job[]
    // loadingStore.stop()
    // data.value = jobs
  }

  /**
   * This will populate the `dataModel` variable with the data model that is used to store the data.
   */
  async function fetchDataModel() {
    const apiBaseUrl = configStore.getEnv().proxy?.general.apiBaseUrl

    if (!apiBaseUrl) {
      throw new Error('📦 API base URL is not available, skipping data model fetch')
    }

    loadingStore.start()
    const response = await fetch(`${apiBaseUrl}/api/v5/datamodel/structure/job`)
    dataModelRoot = await response.json()
    loadingStore.stop()

    console.log('📦 Received data model', dataModelRoot)
  }

  /**
   * Will sign in the user over the WebSocket connection. This will send the id token to the server, which will then
   * authenticate the user and attach the listener to the user's data. The listener will then send the user's data to
   * the client.
   *
   * @returns
   */
  async function attachListener() {
    console.log('📦 [WebSocket] Attaching listener')

    const idToken = authStore.getIdToken()

    if (!idToken) {
      console.error('📦 [WebSocket] No id token found, skipping websocket listener')
      return
    }

    const authRequest: WsAuthRequest = {
      type: 'jwt',
      data: {
        idToken
      }
    }

    return send(authRequest)
  }

  // ===========
  // Methods
  // ===========

  // =================
  // WEBSOCKET 🪢
  // =================

  /**
   * Sends a WebSocket request to the server. This will return a promise that will resolve with the response from the
   * server.
   *
   * @param request The request to send
   * @returns The promise that will resolve with the response from the server
   */
  async function send(request: WsRequest) {
    return new Promise<WsResponse>((resolve, reject) => {
      if (!socket) {
        reject(new Error('📦 [WebSocket] No connection available'))
        return
      }

      const identifiableRequest = { id: uuidv4(), ...request }

      if (messageResponseWaitingQueue.find(message => message.id === identifiableRequest.id)) {
        reject(new Error('📦 [WebSocket] Request id already exists in the waiting queue'))
        return
      }

      // Should there be no response within the timeout, reject the promise
      const timeout = setTimeout(() => {
        reject(new Error('📦 [WebSocket] Request timed out'))
        messageResponseWaitingQueue = messageResponseWaitingQueue.filter(
          message => message.id !== identifiableRequest.id
        )
      }, requestTimeoutMs)

      // Wait for the response
      messageResponseWaitingQueue.push({
        id: identifiableRequest.id,
        promise: { resolve, reject },
        timeout
      })

      socket.send(JSON.stringify(identifiableRequest))
    })
  }

  // ==============
  // PATH FINDER 🛰️
  // ==============

  /**
   * This section contains functions to find the right data within the data tree and update it accordingly.
   */

  /**
   * Helper function to clone a path
   */
  function clonePath(path: TreeNode[]): TreeNode[] {
    return [...path]
  }

  interface PathNode {
    name: DatabaseName
    relationType: 'one' | 'many'
  }

  function convertTreeNodeToPathNode(node: TreeNode): PathNode {
    return {
      name: node.data.name,
      relationType: node.relationType
    }
  }

  function convertTreeNodesToPathNodes(nodes: TreeNode[]): PathNode[] {
    return nodes.map(convertTreeNodeToPathNode)
  }

  /**
   * Function to find all paths between two nodes in a tree
   *
   * @param root The root node of the tree
   * @param start The name of the start model
   * @param end The name of the end model
   * @returns An array of paths between the start and end model
   */
  function findAllPaths(root: TreeNode, to: DatabaseName): PathNode[][] {
    let paths: PathNode[][] = []
    let queue: { node: TreeNode; path: TreeNode[] }[] = [{ node: root, path: [] }]

    while (queue.length > 0) {
      const { node, path } = queue.shift()!
      const newPath = clonePath(path)
      newPath.push(node)

      // Check if the current node is the end model
      if (node.data.name === to) {
        // Convert path nodes to tree nodes
        const pathNodes = convertTreeNodesToPathNodes(newPath)
        paths.push(pathNodes)
      }

      // Add branches to the queue
      for (const branch of node.branches) {
        // To avoid cycles, only add the branch if it's not already in the path
        if (!path.some(p => p.data.name === branch.data.name)) {
          queue.push({ node: branch, path: newPath })
        }
      }
    }

    // Sort paths by length to ensure shortest paths are first
    paths.sort((a, b) => a.length - b.length)

    return paths
  }

  function updateData(dataUpdate: DataUpdate) {
    if (!dataModelRoot) {
      console.error('📦 Data model is not available, skipping data update')
      return
    }

    const databaseName = getDatabaseNameFromModelName(dataUpdate.modelName)
    const paths = findAllPaths(dataModelRoot, databaseName)

    console.log('Found paths', paths.length)

    let p = 0

    for (const path of paths) {
      console.log('Path', p)
      console.log(path.map(node => node.name).join(' -> '))
      p++
      _stepIntoJobNode(path, dataUpdate)
    }
  }

  function _editEndNode(keyPath: (string | number)[], dataUpdate: DataUpdate) {
    let value = _.get(data.value, keyPath)

    if (!value) {
      console.error('Could not find value at key path', keyPath)
      return false
    }

    // This is a senity check to ensure only the correct end node is updated
    if (value.id !== dataUpdate.id) {
      return false
    } else {
      console.log('Updated', keyPath, dataUpdate.id)
    }

    switch (dataUpdate.operation) {
      case 'create':
        console.warn('WIP')
        break
      case 'update':
        value = {
          ...value,
          ...dataUpdate.data[0] // The websocket returns an array of updated data
        }
        _.set(data.value, keyPath, value)
        break
      case 'delete':
        console.warn('WIP')
        break
    }

    return true
  }

  function _stepIntoJobNode(path: PathNode[], dataUpdate: DataUpdate) {
    const pathCopy = [...path]
    const node = pathCopy.shift()

    if (!node) {
      throw new Error('Invalid path. Path has no nodes')
    }

    if (node.name !== 'job') {
      throw new Error('Invalid path begining')
    }

    const jobRelations = dataUpdate.jobRelations
    const jobIds = data.value.map(job => job.id)
    const indicies = jobIds.map((id, index) => (jobRelations.includes(id) ? index : -1)).filter(index => index !== -1)

    if (indicies.length === 0) {
      throw new Error('No job found')
    }

    for (const index of indicies) {
      _stepIntoPath(pathCopy, dataUpdate, [index])
    }
  }

  function _stepIntoPath(path: PathNode[], dataUpdate: DataUpdate, keyPath: (string | number)[] = []) {
    const pathCopy = [...path]
    const node = pathCopy.shift()

    // console.log(keyPath)

    // Reached end node
    if (!node) {
      return _editEndNode(keyPath, dataUpdate)
    }

    // Single node
    if (node.relationType === 'one') {
      const keyPathCopy = [...keyPath]
      const key = node.name

      keyPathCopy.push(key)

      _stepIntoPath(pathCopy, dataUpdate, keyPathCopy)
    }

    // Multiple nodes
    else if (node.relationType === 'many') {
      const keyPathCopy = [...keyPath]
      keyPathCopy.push(node.name)

      const value = _.get(data.value, keyPathCopy)

      if (value === undefined) {
        return false
      }

      if (!Array.isArray(value)) {
        throw new Error('Found non array value. Expected array')
      }

      for (const index of value.keys()) {
        const keyPathCopy1 = [...keyPathCopy]
        keyPathCopy1.push(index)
        _stepIntoPath(pathCopy, dataUpdate, keyPathCopy1)
      }
    }
  }

  return { isInitalized, data, initialize, deinitialize }
})
