import Router from '@/router'
import { EmmyTalkReference, useTalkStore } from '@/pinia/talkStore'

declare global {
  interface Window {
    VUE_APP_WEB_COMPONENT_TALK_URL: string
  }
}

export function useEmmyTalk() {
  const emmyTalkInit = () => {
    // Initialize emmyTalk global event listener to open talk in overlay or fullscreen mode
    document.addEventListener(
      'emmytalk-open',
      function (e) {
        const openEvent: EmmyTalkReference = JSON.parse((e as CustomEvent).detail[0])
        openTalk(openEvent)
      },
      true
    )

    // import talk web components
    import(/* @vite-ignore */ window.VUE_APP_WEB_COMPONENT_TALK_URL)
      .then(({ register }) => {
        register()
        console.log('Talk web component has been loaded')
      })
      .catch(error => {
        console.error('Failed to import the talk web component:', error)
      })
  }

  function openTalk(event: EmmyTalkReference) {
    console.debug('useEmmyTalk.openTalk', event)
    const talkStore = useTalkStore()
    if (event.isOverlay) {
      talkStore.openOverlayTalk(event)
    } else {
      talkStore.openTalk(event)
      Router.push('/emmytalk').then(r => {})
    }
  }

  return {
    emmyTalkInit
  }
}
