export interface PaginatationState {
  total: number
  offset: number
  size: number
  isEnd: boolean
}

export interface PaginatedResult<T> {
  data: T[]
  pagination: PaginatationState
  next?: () => Promise<TypedResponse<PaginatedResult<T>>>
}

export function isPaginatedResult<T>(res: any): res is PaginatedResult<T> {
  return res.data && res.pagination
}
