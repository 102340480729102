import { useAuthStore } from '@/pinia/authStore'
import { useConfigurationStore } from '@/pinia/configStore'

const Entities = {
  Candidates: 'candidates',
  CandidateDocuments: 'candidate-documents',
  Companies: 'companies',
  Documents: 'documents',
  Events: 'events',
  Jobs: 'jobs',
  JobApplications: 'job-applications',
  JobDocuments: 'job-documents',
  JobPersons: 'job-persons',
  Paths: 'paths',
  PathSteps: 'path-steps',
  Persons: 'persons',
  Steps: 'steps'
} as const

export type EntityType = keyof typeof Entities

interface FetcherOptions {
  id?: string

  include?: (keyof typeof Entities)[] | keyof typeof Entities | '*' | string
  filter?: string

  // Only in development mode
  skipRLS?: boolean
  fakeIdTokenOverwrite?: string
}

export async function portalFetcher(entity: EntityType, options: FetcherOptions = {}) {
  const configStore = useConfigurationStore()
  const authStore = useAuthStore()

  if (!configStore.isConfigured) {
    throw new Error('No tenant configuration found while fetching data from the API')
  }

  if (!authStore.isAuthenticated || !authStore.getIdToken()) {
    throw new Error('No authenticated user found while fetching data from the API')
  }

  const baseUrl = useConfigurationStore().getEnv().proxy?.general.apiBaseUrl

  if (!baseUrl) {
    throw new Error('No proxy configured while fetching data from the API')
  }

  if (options.fakeIdTokenOverwrite) {
    console.warn('Using fake id token for fetching data from the API')
  }

  if (options.skipRLS) {
    console.warn('Skipping RLS for fetching data from the API')
  }

  const idToken = options.fakeIdTokenOverwrite ?? authStore.getIdToken()!

  // Parse include options
  const include = Array.isArray(options.include) ? options.include.join(',') : options.include

  const searchParams = new URLSearchParams()
  if (include) searchParams.append('include', include)
  if (options.filter) searchParams.append('filter', options.filter)
  if (options.skipRLS) searchParams.append('skip_rls', 'true')

  const idUriPart = options.id ? `/${options.id}` : ''
  const entityUriPart = Entities[entity]

  const url = `${baseUrl}/api/v5/${entityUriPart}${idUriPart}?${searchParams.toString()}`

  // FIXME: 31.05.2024 The api is able to determine the tenant id from the id token. But as of right now there is no longer a "tenant_id" property inside the id token.
  const tenantId = configStore.getOrganizationId()

  return fetch(url, {
    headers: {
      idtoken: `${idToken}`,
      ...(tenantId ? { 'x-tenant': tenantId } : {})
    }
  })
}
