import { createRouter, createWebHistory } from '@ionic/vue-router'
import { useAuthStore } from '@/pinia/authStore'
import { useConfigurationStore } from '@/pinia/configStore'

import { useProjectHistory } from '@/composables/useProjectHistory'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/LoginPage.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home/Home.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('../views/Project/ProjectsView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projects/:id',
    name: 'Project',
    component: () => import('../views/Project/ProjectView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/candidates',
    name: 'Candidates',
    component: () => import('../views/Candidate/CandidatesView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/candidates/:id',
    name: 'Candidate',
    component: () => import('../views/Candidate/CandidateView.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/emmytalk',
    name: 'Emmytalk',
    component: () => import('../views/Emmy/Emmytalk.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/emmy-bi/aktuell',
    name: 'Report-Aktuell',
    component: () => import('../views/Report/Aktuell.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/emmy-bi/verlauf',
    name: 'Report-Verlauf',
    component: () => import('../views/Report/Verlauf.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/emmy-bi/zielfirmen',
    name: 'Report-Zielfirmen',
    component: () => import('../views/Report/Zielfirmen.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const baseUrl = '/'

const router = createRouter({
  history: createWebHistory(baseUrl),
  routes
})

router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore()
  const configurationStore = useConfigurationStore()
  const { pushProjectToHistory } = useProjectHistory()

  if (!authStore.isInitialized && to.meta.requiresAuth) {
    console.warn('🚏 Route guard called before authStore is initialized')
  }

  console.log('🚏 Route from ' + from.name + ' to ' + to.name)

  if (to.name === 'Project') {
    console.log('🚏 Route guard: Setting project id to ' + to.params.id)
    pushProjectToHistory(to.params.id)
  }

  await authStore.updateState()
  const isAuthenticated = authStore.isAuthenticated

  // When a user opens up the /login route and is already authenticated, redirect to home
  // Only on initial load, when the from route name is undefined
  if (isAuthenticated && to.path === '/login' && !from.name) {
    next('/')
    console.log('🚏 Route blocked by route guard:', 'User is already authenticated, redirecting to home')
    return
  }

  // Will act as an guard statement and will fall through when the route does not require authentication
  if (to.meta.requiresAuth) {
    if (!isAuthenticated || !configurationStore.orgId) {
      // When the user is not authenticated, redirect to login
      next('/login')
      console.log('🚏 Route blocked by route guard:', 'User is not authenticated')
      return
    }

    // FIXME: Re-enable when project permissions are implemented
    // if (!authStore.hasProjectPermissions()) {
    //   // When the user is authenticated but does not have any projects assigned, redirect to login
    //   next('/login')
    //   console.log('🚏 Route blocked by route guard:', 'User has no projects assigned')
    //   return
    // }

    next()
    return
  }

  next()
})

export default router
