export default {
  resources: {
    projects: 'projects',
    jobs: 'jobs',
    candidates: 'candidates',
    chats: 'tickets',
    news: 'news',
    documents: 'documents',
    documentsRaw: 'raw',
    notifications: 'notifications',
    deviceId: 'notifications/deviceId',
    talks: 'talks/findAll'
  },
  apiKey: undefined,
  apiType: {
    portal: 'emmy_portal',
    talk: 'emmy_talk'
  }
} as const
