<template>
  <ion-segment
    :key="new Date().getMilliseconds()"
    scrollable
    :value="activeSegment"
    @ion-change="segmentChanged($event)"
  >
    <ion-segment-button value="all" class="tab">
      <ion-label>{{ $t('emmyTalk.messagesTab.title') }}</ion-label>
    </ion-segment-button>
    <ion-segment-button value="candidates" class="tab">
      <ion-label>{{ $t('emmyTalk.candidatesTab.title') }}</ion-label>
    </ion-segment-button>
    <ion-segment-button value="project" class="tab">
      <ion-label>{{ $t('emmyTalk.projectsTab.title') }}</ion-label>
    </ion-segment-button>
  </ion-segment>

  <search class="search" />

  <!-- <MessagesOverviews v-if="segment" :active-segment="activeSegment" :is-overlay="isOverlay" /> -->
</template>

<script>
import { IonSegment, IonSegmentButton, IonLabel } from '@ionic/vue'

import Search from '../components/Search.vue'
// import MessagesOverviews from './MessageOverviews.vue'
import { useTalkStore } from '@/pinia/talkStore'
import { storeToRefs } from 'pinia'

export default {
  name: 'Segments',
  components: {
    IonSegment,
    IonSegmentButton,
    IonLabel,
    Search
    // MessagesOverviews
  },
  props: { isOverlay: Boolean },
  setup() {
    const talkStore = useTalkStore()
    const { activeSegment } = storeToRefs(talkStore)
    return {
      talkStore,
      activeSegment
    }
  },
  data: function () {
    return {
      allowedSegments: ['all', 'candidates', 'project'],
      segment: 'all'
    }
  },
  methods: {
    segmentChanged(ev) {
      this.talkStore.changeActiveSegment(ev.detail.value)
      this.segment = ev.detail.value
    }
  }
}
</script>

<style scoped lang="scss">
ion-segment {
  margin-left: 6px;
  margin-right: 6px;
}
ion-label {
  font-size: 1.4rem;
  opacity: 0.6;
  font-weight: 600;
  text-transform: none;
  color: #f5f6fb;
}

.segment-button-checked {
  &::part(indicator-background) {
    width: 80%;
    margin: 0 auto;
    --indicator-height: 4px;
    background: var(--background-selected);
  }

  ion-label {
    color: var(--font-white);
    opacity: 1;
  }
}

.search {
  margin-top: 4px;
}

.placeholder {
  min-height: 72px;
}
</style>
