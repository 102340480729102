import z from 'zod'

export function getEnvironmentVariables() {
  const envSchema = z.object({
    VUE_APP_WEB_COMPONENT_URL: z.string(),
    VUE_APP_API_PROXY_SERVER_URL: z.string(),
    VUE_APP_WEB_COMPONENT_TALK_URL: z.string(),
    VUE_APP_WEB_COMPONENT_TALK_API_URL: z.string(),
    VUE_APP_ORGANIZATION_ID: z.string().optional(),
    VUE_APP_ENVIRONMENT: z.string().default('production')
  })

  const result = envSchema.safeParse(window)

  if (!result.success) {
    console.error('Error while parsing environment variables. More details:', result.error)
    throw new Error('Invalid environment configuration')
  }

  return result.data
}
