<script lang="ts" setup>
import { IonSearchbar, IonButton, IonLabel } from '@ionic/vue'
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useProjectStore } from '@/pinia/projectStore'
import { fetcher } from '../../../http'

const store = useStore()
const projectStore = useProjectStore()
const results = ref<any[]>([])
const isActive = ref<boolean>(true)

const toggleActiveStatus = () => {
  isActive.value = !isActive.value
  store.dispatch('TOGGLE_SHOW_INACTIVE', results.value)
}

const handleSearch = async (data: string) => {
  results.value = store.state.chats.filter(talk => talk.title.toLowerCase().includes(data.toLowerCase()))
  store.dispatch('FILTER_MESSAGES', results.value)

  results.value = store.state.marketNews.filter(message => message.text.toLowerCase().includes(data.toLowerCase()))
  store.dispatch('FILTER_MARKET_NEWS', results.value)

  const candidates: any[] = []
  projectStore.projects.forEach(project =>
    project.jobApplications.forEach(jobApplication => {
      candidates.push(jobApplication)
    })
  )

  results.value = candidates.filter(candidate => candidate.candidateName.toLowerCase().includes(data.toLowerCase()))
  store.dispatch('FILTER_CANDIDATES', results.value)

  results.value = store.state.projects.filter(project => project.name.toLowerCase().includes(data.toLowerCase()))
}
</script>

<template>
  <div class="searchbar">
    <ion-searchbar
      placeholder="Search..."
      inputmode="search"
      type="search"
      enterkeyhint="search"
      autocomplete="off"
      autocorrect="off"
      @ion-change="handleSearch($event.target.value)"
    />
    <ion-button @click="toggleActiveStatus">
      <ion-label v-if="isActive" class="active-label">
        {{ $t('emmyTalk.search.active') }}
      </ion-label>
      <ion-label v-else class="active-label">
        {{ $t('emmyTalk.search.inActive') }}
      </ion-label>
    </ion-button>
  </div>
</template>

<style scoped lang="scss">
.searchbar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

ion-searchbar {
  height: 42px;
  margin: 10px;
  padding: 0;
  --border-radius: 4px;
  --placeholder-color: var(--font-secondary);
  --icon-color: #9a9eb3;
}

ion-button {
  height: 42px;
  margin: 10px 10px 10px 0;
  // --padding-top: 25px;
  // --padding-bottom: 25px;
  // --padding-start: 15px;
  // --padding-end: 15px;
  --background: #fff;
  ion-icon {
    color: #9a9eb3;
  }
}

.active-label {
  color: rgb(38, 38, 38);
  text-transform: none;
}
</style>
