export interface Pipeline {
  activeCommunication: PipelineStep
  contacted: PipelineStep
  contractNegotiations: PipelineStep
  evaluation: PipelineStep
  firstInterview: PipelineStep
  followUpInterview: PipelineStep
  handover: PipelineStep
  placement: PipelineStep
  prequalification: PipelineStep
  presentation: PipelineStep
}

interface PipelineStep {
  current: number
  rejected: number
  total: number
}

const defaultStep: PipelineStep = { current: 0, rejected: 0, total: 0 }

export const defaultPipeline: Pipeline = {
  activeCommunication: { ...defaultStep },
  contacted: { ...defaultStep },
  contractNegotiations: { ...defaultStep },
  evaluation: { ...defaultStep },
  firstInterview: { ...defaultStep },
  followUpInterview: { ...defaultStep },
  handover: { ...defaultStep },
  placement: { ...defaultStep },
  prequalification: { ...defaultStep },
  presentation: { ...defaultStep }
}
