<script lang="ts" setup>
import { IonButtons, IonHeader, IonMenu, IonMenuButton, IonToolbar } from '@ionic/vue'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'

import { LocalizedString } from '@/services/api/models/localized-string.model'

import { useAuthStore } from '@/pinia/authStore'
import { useConfigurationStore } from '@/pinia/configStore'

import AccordionArrowDown from '@/assets/accordion-arrow.svg'
import CandidatesIcon from '@/assets/candidates-icon.svg'
import EmmyBiIcon from '@/assets/emmy-bi-icon.svg'
import EmmyTalkIcon from '@/assets/emmy-talk-icon.svg'
import EmmySoftLogo from '@/assets/es_logo.svg'
import HomeIcon from '@/assets/home-icon.svg'
import ProjectsIcon from '@/assets/projects-icon.svg'

import { useProjectHistory } from '@/composables/useProjectHistory'
import { Permission } from '@/models/Permission'

import LocalizedLabel from '../LocalizedLabel.vue'

// =======
// State
// =======

const { t } = useI18n()

const route = useRoute()
const router = useRouter()

const configStore = useConfigurationStore()
const authStore = useAuthStore()

const { historicProjects } = useProjectHistory()

const hoverIndex = ref<number | undefined>(undefined)

const openedSubmenus = ref<Set<number>>(new Set())

// =======
// Interfaces
// =======

/**
 * An interface for the sidebar projects
 */
export interface SidebarProject {
  id: string
  name: string
  color: string
  clientName: string
  createdDate: string
}

interface MenuItem {
  icon: any
  label: string | LocalizedString
  indicator?: 'circle' | 'square'
  subLabel?: string | LocalizedString
  route?: string
  highlight?: boolean
  subMenu?: MenuItem[]
}

interface BottomMenuItem {
  logoSrc: string
  altLabel: string
  route: string
  isVisible: boolean
}

// =======
// Computed
// =======

const baseUrls = computed(() => {
  return configStore.getEnv().proxy?.baseUrl ?? {}
})

const haveAccessToScore = computed(() => {
  return authStore.checkUserHavePermission(Permission.EmmyScoreAccess)
})

const haveAccessToCore = computed(() => {
  return authStore.checkUserHavePermission(Permission.EmmyCoreAccess)
})

const historicProjectMenuItems = computed<MenuItem[]>(() => {
  return historicProjects.value.map(project => ({
    icon: ProjectsIcon,
    label: project.nameLocalized,
    indicator: 'circle',
    subLabel: project.companyName,
    route: `/projects/${project.id}`
  }))
})

const menuItems = computed<MenuItem[]>(() => [
  {
    icon: HomeIcon,
    label: t('sidebar.home'),
    route: '/'
  },
  {
    icon: ProjectsIcon,
    label: t('sidebar.projects'),
    route: '/projects',
    subMenu: [
      ...historicProjectMenuItems.value,
      {
        icon: ProjectsIcon,
        label: t('sidebar.allProjects'),
        route: '/projects',
        highlight: false
      }
    ]
  },
  {
    icon: CandidatesIcon,
    label: t('sidebar.candidates'),
    route: '/candidates'
  },
  {
    icon: EmmyBiIcon,
    label: t('sidebar.emmybi.title'),
    subMenu: [
      {
        icon: EmmyBiIcon,
        label: t('sidebar.emmybi.current'),
        route: '/emmy-bi/aktuell'
      },
      {
        icon: EmmyBiIcon,
        label: t('sidebar.emmybi.history'),
        route: '/emmy-bi/verlauf'
      },
      {
        icon: EmmyBiIcon,
        label: t('sidebar.emmybi.targetCompanies'),
        route: '/emmy-bi/zielfirmen'
      }
    ]
  },
  {
    icon: EmmyTalkIcon,
    label: t('sidebar.emmyTalk'),
    route: '/emmytalk'
  }
])

const bottomMenuItems = computed<BottomMenuItem[]>(() => {
  return [
    {
      logoSrc: '/static/emmycore-logo.svg',
      altLabel: 'EmmyCore Link',
      route: baseUrls.value.core ?? '',
      isVisible: haveAccessToCore.value
    },
    {
      logoSrc: '/static/emmyscore-logo.svg',
      altLabel: 'EmmyScore Link',
      route: baseUrls.value.score ?? '',
      isVisible: haveAccessToScore.value
    }
  ]
})

// =======
// Methods
// =======

function openLink(link: string) {
  router.push(link)
  collapseSidebar()
}

function openExternalLink(link: string) {
  window.open(link, '_blank')
}

function openSubmenu(index: number) {
  if (menuItems.value[index].subMenu === undefined) return
  openedSubmenus.value.add(index)
}

function closeSubmenu(index: number) {
  openedSubmenus.value.delete(index)
}

function toggleSubmenu(index: number) {
  if (isOpened(index)) {
    closeSubmenu(index)
  } else {
    openSubmenu(index)
  }
}

function isOpened(index: number) {
  return openedSubmenus.value.has(index)
}

function isActive(item: MenuItem) {
  return route.path === item.route
}

function collapseSidebar() {
  const menu = document.querySelector('ion-menu')

  if (menu) {
    menu.close(true)
  }
}
</script>

<template>
  <IonMenu v-show="route.name !== 'Login'" content-id="main-content" type="overlay" swipe-gesture>
    <IonHeader :translucent="true">
      <IonToolbar>
        <IonButtons>
          <template #start>
            <IonMenuButton />
          </template>
        </IonButtons>

        <EmmySoftLogo class="ml-6 mt-[2px]" />
      </IonToolbar>
    </IonHeader>

    <div class="flex flex-col grow overflow-hidden h-full">
      <!-- TOP -->
      <div class="flex flex-col h-full bg-[#236daa] overflow-y-scroll scrollbar-hide">
        <template v-for="(item, index) in menuItems" :key="item.route">
          <div class="flex items-center">
            <button
              class="flex grow items-center gap-4 text-white w-full transition-colors pl-8 py-4"
              :class="{ 'bg-[#1f6197]': hoverIndex === index && item.route, 'bg-[#f7941e]': isActive(item) }"
              @mouseover="hoverIndex = index"
              @mouseout="hoverIndex = undefined"
              @click="item.route ? openLink(item.route) : toggleSubmenu(index)"
            >
              <component :is="item.icon" class="size-10" />

              <div class="flex flex-col items-start gap-2">
                <p class="text-[14px]">
                  <LocalizedLabel :value="item.label" />
                </p>
                <p v-if="item.subLabel" class="text-[12px] text-gray-300">
                  <LocalizedLabel :value="item.subLabel" />
                </p>
              </div>
            </button>

            <button
              v-if="item.subMenu"
              class="pr-8 h-full transition-colors"
              :class="{ 'bg-[#1f6197]': hoverIndex === index && item.route, 'bg-[#f7941e]': isActive(item) }"
              @click="toggleSubmenu(index)"
            >
              <AccordionArrowDown
                class="size-10 transform rotate-0 text-white opacity-70 hover:opacity-100 transition-all rounded-full"
                :class="{ '!rotate-180': isOpened(index) }"
              />
            </button>
          </div>

          <div v-if="item.subMenu && isOpened(index)">
            <template v-for="subItem in item.subMenu" :key="subItem.route">
              <button
                class="flex items-center gap-4 text-white w-full px-6 text-[14px] min-h-20 bg-[#1a5483] hover:bg-[#174a76] transition-colors"
                @click="openLink(subItem.route)"
              >
                <div
                  v-if="subItem.indicator"
                  class="size-2 bg-white"
                  :class="{ 'rounded-full': subItem.indicator === 'circle' }"
                />
                <div class="flex flex-col items-start gap-2">
                  <p
                    class="text-[14px] transition-colors"
                    :class="{
                      'font-semibold': isActive(subItem) && subItem.highlight !== false,
                      'text-[#f7941e]': isActive(subItem) && subItem.highlight !== false
                    }"
                  >
                    <LocalizedLabel :value="subItem.label" />
                  </p>
                  <p
                    v-if="subItem.subLabel"
                    class="text-[12px] text-gray-300 transition-colors"
                    :class="{ 'text-[#f7941e]': isActive(subItem) && subItem.highlight !== false }"
                  >
                    <LocalizedLabel :value="subItem.subLabel" />
                  </p>
                </div>
              </button>
            </template>
          </div>
        </template>
      </div>

      <!-- BOTTOM -->

      <button
        v-for="item in bottomMenuItems.filter(x => x.isVisible)"
        :key="item.route"
        class="flex sticky bottom-0 bg-white w-full items-center py-6 px-6 hover:bg-gray-200 transition-colors"
        @click="openExternalLink(item.route)"
      >
        <img :src="item.logoSrc" :alt="item.altLabel" class="max-h-10" />
        <div class="grow" />
        <img src="/static/external-link.svg" alt="External Link" class="size-8" />
      </button>
    </div>
  </IonMenu>
</template>

<style lang="scss" scoped>
.item-background-color {
  --ion-item-background: #236daa;
}

.item-background-color:hover {
  --ion-item-background: #1a5483;
}
</style>
