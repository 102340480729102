<script lang="ts" setup>
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer'
import { storeToRefs } from 'pinia'

import { useAuthStore } from '@/pinia/authStore'
import { useConfigurationStore } from '@/pinia/configStore'
import { usePdfViewerStore } from '@/pinia/pdfViewerStore'

import { apiService } from '@/lib/apiService'

// PROPERTIES

const props = defineProps({
  document: {
    type: String,
    required: true
  },
  documentId: {
    type: String,
    required: true
  }
})

const { document, documentId } = toRefs(props)

// REFERENCES

const { locale } = useI18n()

const outputElement = ref<HTMLElement>(null)

const configurationStore = useConfigurationStore()
const pdfViewerStore = usePdfViewerStore()
const authStore = useAuthStore()

const { env } = storeToRefs(configurationStore)
const { fullscreenDocument, fullscreenDocumentId, isFullscreenDocumentAvailable } = storeToRefs(pdfViewerStore)

// COMPUTED

const viewer = ref<WebViewerInstance>()

// WATCHERS

/**
 * Updates the WebViewer language when the locale changes.
 */
watch(locale, function updateLanguage(newVal) {
  viewer.value?.UI.setLanguage(newVal)
})

/**
 * Loads a new document when the document prop changes.
 */
watch(document, function loadNewDocument(newVal) {
  viewer.value?.Core.documentViewer.loadDocument(newVal)
})

// LIFE CYCLE

onMounted(async function initializeWebViewer() {
  const instance = await initWebViewer()
  configureUI(instance)
  setupDocumentEvents(instance)
})

// METHODS

/**
 * Initializes the WebViewer instance with the provided configuration.
 * @returns {Promise<WebViewerInstance>} The initialized WebViewer instance.
 */
async function initWebViewer() {
  const instance = await WebViewer(
    {
      path: '/webviewer',
      licenseKey: env.value.proxy.pdfExpress.web.key,
      initialDoc: document.value,
      extension: 'pdf',
      annotationUser: authStore.getFullName()
    },
    outputElement.value!
  )

  viewer.value = instance
  return instance
}

/**
 * Configures the UI of the WebViewer instance.
 * @param {WebViewerInstance} instance - The WebViewer instance to configure.
 */
function configureUI(instance: WebViewerInstance) {
  instance.UI.disableElements(['saveAsButton', 'printButton'])
  instance.UI.updateElement('downloadButton', {
    onClick: function handleDownload() {
      window.open(document.value, '_blank')
    }
  })

  setupResponsiveUI(instance)
  setupHeaderItems(instance)
  instance.UI.setLanguage(navigator.language)
  instance.UI.setToolbarGroup(instance.UI.ToolbarGroup.VIEW)
}

/**
 * Sets up responsive UI behavior for the WebViewer instance.
 * @param {WebViewerInstance} instance - The WebViewer instance to set up.
 */
function setupResponsiveUI(instance: WebViewerInstance) {
  /**
   * Adapts the UI based on the window width.
   * @param {number} width - The current window width.
   */
  function adaptUI(width: number) {
    instance.UI.disableElements([
      'panToolButton',
      'zoomInButton',
      'zoomOutButton',
      'viewControlsButton',
      'selectToolButton'
    ])
    if (width >= 600) instance.UI.enableElements(['panToolButton'])
    if (width > 490) instance.UI.enableElements(['zoomInButton', 'zoomOutButton', 'viewControlsButton'])
    if (width > 720) instance.UI.enableElements(['selectToolButton'])
  }

  window.addEventListener('resize', function handleResize() {
    adaptUI(window.innerWidth)
  })
  adaptUI(window.innerWidth)
}

/**
 * Sets up header items for the WebViewer instance based on fullscreen availability.
 * @param {WebViewerInstance} instance - The WebViewer instance to set up.
 */
function setupHeaderItems(instance: WebViewerInstance) {
  if (isFullscreenDocumentAvailable.value) {
    instance.UI.setHeaderItems(function addCloseButton(header) {
      header.push({
        type: 'actionButton',
        img: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>',
        onClick: function handleClose() {
          pdfViewerStore.dismiss()
        }
      })
    })
  } else {
    instance.UI.setHeaderItems(function addFullscreenButton(header) {
      header.push({
        type: 'actionButton',
        img: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-maximize-2"><polyline points="15 3 21 3 21 9"></polyline><polyline points="9 21 3 21 3 15"></polyline><line x1="21" y1="3" x2="14" y2="10"></line><line x1="3" y1="21" x2="10" y2="14"></line></svg>',
        onClick: function handleFullscreen() {
          fullscreenDocument.value = document.value
          fullscreenDocumentId.value = documentId.value
        }
      })
    })
  }
}

/**
 * Sets up document events for the WebViewer instance.
 * @param {WebViewerInstance} instance - The WebViewer instance to set up.
 */
function setupDocumentEvents(instance: WebViewerInstance) {
  const { documentViewer, annotationManager } = instance.Core

  documentViewer.addEventListener('documentLoaded', async function handleDocumentLoaded() {
    console.log(`Document is loaded, document: ${document.value}`)
    console.log(`Document is loaded, document id: ${documentId.value}`)
    const xfdfData = await apiService.getXfdfData(documentId.value)
    if (xfdfData && xfdfData.xfdf.length > 0) {
      const annotations = await annotationManager.importAnnotations(xfdfData.xfdf)
      await annotationManager.drawAnnotationsFromList(annotations)
    }
  })

  annotationManager.addEventListener(
    'annotationChanged',
    async function handleAnnotationChanged(annotations, action, { imported }) {
      if (imported) return
      const xfdfString = await annotationManager.exportAnnotations()
      await apiService.addXfdfData(documentId.value, xfdfString)
    }
  )
}
</script>

<template>
  <div ref="outputElement" :class="{ 'viewer-padding': isFullscreenDocumentAvailable }"></div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div {
  width: 100%;
  height: 100vw;
  max-height: 85vh;
}

.viewer-padding {
  background-color: #f8f9fa;
  padding-top: env(safe-area-inset-top);
}

:deep(iframe) {
  border: none;
}
</style>
